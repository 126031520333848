import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-breadcrumbs',
  templateUrl: './new-breadcrumbs.component.html',
  styleUrls: ['./new-breadcrumbs.component.scss']
})
export class NewBreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb[];
  constructor() { }

  ngOnInit(): void {
  }

}

export class Breadcrumb {
  name: string;
  icon: string;
  url: string;
  last: boolean;
}
