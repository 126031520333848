@if (!mobileScreen) {
  <div class="modal-container">
    <div class="modal-header">
      <button
        type="button"
        class="close pull-right"
        aria-label="Fechar"
        (click)="closeModal()"
      >
        <i class="bi bi-x icon__close"></i>
      </button>
    </div>
    <div class="modal-actions">
      <div class="container-checkbox">
        <input
          [(ngModel)]="isChecked"
          (change)="setInfoLocalStorage($event)"
          type="checkbox"
        />
        <label>Não mostrar novamente</label>
      </div>

      <div class="containers-button">
        <button
          class="button"
          (click)="getNewOrientation()"

        >
          <span>Conheça agora <i class="bi bi-arrow-right-short icon-button"></i></span>
        </button>
      </div>
    </div>
  </div>

}
@else {
  <section #modalMobile class="container-dad-mobile">

    <section class="container-buttons">
      <button
        (click)="getNewOrientation()"
      class=" btn button-get-orientation">
        <span>Conheça agora <i class="bi bi-arrow-right-short icon-button"></i></span>
      </button>
      <button class="btn button-not-show-again"
      (click)="setNotViewMoreMobile()"
      >Não mostrar novamente</button>

    </section>
  </section>

  @defer (on timer(200ms)) {

    <button (click)="closeModal()" class="btn close-modal">
        Fechar
    </button>
  }
}
