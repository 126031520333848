import {AgeByAge} from '../../product/models/filters-products-model';

export class CalculatorProductsResultModel {
  offerCookies: boolean;
  offerWetFood: boolean;
  dryFoodMessage?: string;
  dryFood?: ProductCalculationResult[];
  cookies?: ProductCalculationResult[];
  wetFood?: ProductCalculationResult[];
  obesityTreatment?: ObesityTreatment;
  dryFoodAlertMessage?: string;
  canChangeDryProduct?: boolean;
}

export class ProductCalculationResult {
  product: Product;
  gramsPerDay: number;
  dailyCalories: number;
  suggestedPortionAmount: number;
  cookieAmount: number;
  wetAmount: number;
  cookieAndWetAmount: number;
  cookieUnitaryValue: number;
  wetUnitaryValue: number;
  wetGramsValue: number;
  cookieAndWetUnitaryValue: number;
  cookieAndWetGramsValue: number;
  percentualTreats: PercentualTreats;
  gradualExchanges: GradualExchange[] | null;
  selected: boolean = false;
  clinicalNutritionMessage?: GenericMessage;
  gastroenteropathyMessage?: GenericMessage;
}

export class Product {
  name: string;
  specieId: number;
  specieName: string;
  productLineId: number;
  productLineName: string;
  ageGroupId?: number[] | null;
  ageGroupName?: string[] | null;
  sizeId?: number[] | null;
  sizeName?: string[] | null;
  flavor?: null;
  clinicalConditionId: number;
  productTypeId: number;
  clinicalCharacteristicName: string;
  raceId: number;
  raceName: string;
  active: boolean;
  productImageUrl: string;
  productUrl: string;
  weight: number;
  id: number;
  formattedName?: null;
  ageGroupFormattedName: string;
  sizeFormattedName: string;
  offersCookie: boolean;
  offersWetFood: boolean;
  sku: string;
  shortName: string;
  productLinePriority: number;
  ageGroupByAge: AgeByAge;
}

export class PercentualTreats {
  percentageCookie: number;
  percentageWetFood: number;
  percentageCookieWetFood: number;
}

export class GradualExchange {
  id: number;
  day: string;
  description: string;
  imageName: string;
  percentageNewFood: number;
  percentagePreviousFood: number;
}

export class ObesityTreatment {
  initialWeight?: number;
  targetWeight: number;
  treatmentDurationInMonths: number;
  previousTreatmentDurationInMonths?: number;
  weightToLose: number;
  previousWeightToLose?: number;
  dailyKcal: number;
  previousDailyKcal?: number;
  monthlyProgress: MonthlyProgress[];
  date: string;
  returnDate: string;
  isReturn: boolean;
  isComplete: boolean;
}

export interface MonthlyProgress {
  date: Date;
  dateFormatted: string;
  weight: number;
  monthlyWeightLoss: number;
  gramsPerDay: number;
  nextReturn: boolean;
  currentOrientation: boolean;
  realWeightLoss?: number;
  firstObesityTreatment: boolean;
}

export interface GenericMessage {
  id: number;
  message: string;
  moreInformation: GenericMessageMoreInformation;
  toast: boolean;
}

export interface GenericMessageMoreInformation {
  title: string;
  body: boolean;
}
