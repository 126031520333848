import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-select-tags',
  templateUrl: './select-tags.component.html',
  styleUrls: ['./select-tags.component.scss']
})
export class SelectTagsComponent implements OnChanges {
  @Input() width = '100%';
  @Input() label = '';
  @Input() items: any[] = [];
  @Input() optionTitle: string;
  @Input() disabled = false;
  @Input() tagColor = '#C8E3FF';
  @Input() textColor = '#0069D9';
  @Input() required = false;
  @Input() errorMessage = 'Campo obrigatório.';
  @Input() optionsAlreadySelected: any[]; // Used to set the options already selected
  @Input() formSubmitted = false;
  @Input() placeholder = 'Selecione';
  @Output() optionsSelectedChange = new EventEmitter<any>();
  selectedOption: any = null;
  @Input() optionsSelected: any[] = [];
  @Output() optionManuallySelected = new EventEmitter();
  options: any[] = [];
  optionsTouched = false;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.optionsSelected = [];
    }

    if (changes.optionsAlreadySelected) {
      if (this.optionsAlreadySelected) {
        this.optionsSelected = this.optionsAlreadySelected || [];
        this.updateOptionsAfterSelection();
      }
    }

    if (changes.items) {
      this.options = this.items?.filter(item => !this.optionsSelected.includes(item));
    }
  }

  onOptionSelected(value: any, manuallySelected = false): void {
    if (!value || value == 'null') {
      return;
    }

    this.optionsTouched = true;
    this.optionsSelected.push(value);
    this.optionsSelectedChange.emit(this.optionsSelected);
    this.options = this.options.filter(option => option.id != value.id);

    if (manuallySelected) {
      this.optionManuallySelected.emit();
    }
  }

  updateOptionsAfterSelection(): void {
    this.optionsSelectedChange.emit(this.optionsSelected);
    this.options = this.items?.filter(option => !this.optionsSelected?.includes(option));
  }

  removeTag(tag: any): void {
    this.optionsTouched = true;
    this.optionsSelected = this.optionsSelected.filter(option => option.id != tag.id);
    this.optionsSelectedChange.emit(this.optionsSelected);
    this.options = [...this.options, tag].sort((a, b) => a[this.optionTitle].localeCompare(b[this.optionTitle]));
    this.selectedOption = 'null';
    this.optionManuallySelected.emit();
  }
}
