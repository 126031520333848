import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { AppListResponse, AppResponse } from '../shared/app.response';
import { ClinicItem } from './models/clinic-model';
import { LoadingService } from '../shared/loading.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  private apiUrl = `${environment.baseApimUrl}/clinica`;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService
  ) {}

  getClinics(
    pageNumber: number,
    itensPerPage: number
  ): Observable<AppListResponse<ClinicItem>> {
    const headers = this.loadingService.getLoadingHeader();
    const request = {
      SearchExpression: '',
      Filters: [
        {
          Key: 'veterinarioId',
          Operator: 'Equals',
          Value: this.authenticationService.getUserId(),
        },
        {
          Key: 'isDeleted',
          Operator: 'Equals',
          Value: false,
        },
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: pageNumber,
      PageSize: itensPerPage,
    };

    return this.http.post<AppListResponse<ClinicItem>>(
      `${this.apiUrl}/list`,
      request,
      { headers }
    );
  }
  filterClinicByName(
    value: string,
    pageNumber = 0,
    itensPerPage = 10
  ): Observable<AppListResponse<ClinicItem>> {
    const request = {
      SearchExpression: '',
      Filters: [
        {
          Key: 'veterinarioId',
          Operator: 'Equals',
          Value: this.authenticationService.getUserId(),
        },
        {
          Key: 'nome',
          Operator: 'StartsWith',
          Value: value,
        },
        {
          Key: 'isDeleted',
          Operator: 'Equals',
          Value: false,
        },
      ],
      PageSize: itensPerPage,
      PageNumber: pageNumber,
      SortField: 'nome',
      SortOrder: 2,
    };

    return this.http.post<AppListResponse<ClinicItem>>(
      `${this.apiUrl}/list`,
      request
    );
  }
  createClinic(clinic: ClinicItem): Observable<AppResponse<ClinicItem>> {
    const headers = this.loadingService.getLoadingHeader();
    clinic.veterinarioId = this.authenticationService.getUserId();
    const url = `${this.apiUrl}/create`;

    return this.http.post<AppResponse<ClinicItem>>(url, clinic, { headers });
  }

  editClinic(
    clinic: ClinicItem,
    clinicId: number
  ): Observable<AppResponse<ClinicItem>> {
    const headers = this.loadingService.getLoadingHeader();
    clinic.veterinarioId = this.authenticationService.getUserId();
    const url = `${this.apiUrl}/${clinicId}/update`;
    return this.http.put<AppResponse<ClinicItem>>(url, clinic, { headers });
  }

  deleteClinic(clinicId: number): Observable<void> {
    const headers = this.loadingService.getLoadingHeader();
    const url = `${this.apiUrl}/${clinicId}/delete`;

    return this.http.delete<void>(url,  { headers });
  }

  uploadLogo(file: File): Observable<string> {
    const headers = this.loadingService.getLoadingHeader();
    const url = `${this.apiUrl}/arquivo`;
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<string>(url, formData, { headers }).pipe(
      map((res: any) => res.url));
  }
}
