import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyText]'
})
export class OnlyTextDirective {

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('keypress', ['$event']) onKeyDown(e: KeyboardEvent) {
    const isString = /^([^0-9]*)$/.test(e.key);
    !isString ? e.preventDefault() :  false;
  }
}
