import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {
  private title: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor() {}
  getTitle(): BehaviorSubject<string> {
    return this.title;
  }

  setTitle(description: string): void {
    this.title.next(description);
  }
}
