<div class="score-container">
  @defer (when showContainer) {
  <label class="corporal-condition-title"
    >Selecione a condição corporal(ECC) do pet:</label
  >

  @if (!mobileScreen) {

  <div class="circles-container">
    @for (numberScore of scores; track $index) {
    <div
      id="{{numberScore.order}}"
      (click)="showDescription(numberScore)"
      class="circle"
      [ngClass]="{
        selected: numberScore.order === scoreSelected.order
      }"
    >
      {{ numberScore.order }}

      @if (numberScore.order === scoreSelected.order) {
      <div class="arrow"></div>
      }
    </div>
    }
  </div>

  <div class="description-container">
    @if (visibleDescription || scoreSelected) { @if (scoreSelected) {
    <div class="card-description">
      <div class="description">
        <h5 class="description__title">
          {{ scoreSelected.order }} -
          {{ capitalizeString(scoreSelected.name) }}
        </h5>
        @if (scoreSelected?.detail) {
        <p class="description__detail">{{ scoreSelected.detail }}</p>
        }
        <p class="description__text">
          {{ scoreSelected.description }}
        </p>
      </div>

      <img
        class="pet-condition"
        [ngClass]="{
          'pet-condition-with-detail': scoreSelected.detail && obesityTreatment
        }"
        [src]="scoreSelected.url"
        alt="Condição do Pet"
      />

      @if (obesityTreatment) {
      <div
        [ngStyle]="
          obesityTreatment?.isReturn ? { marginTop: '42px' } : { marginTop: '0' }
        "
        class="vertical-line"
      ></div>

      @if (!showLoading) {

        @if (obesityTreatment?.isReturn) {
      <div class="return-tag">Retorno</div>
      }
      <div
        class="treatment-information"
        [ngStyle]="
          obesityTreatment.isReturn ? { marginTop: '42px' } : { marginTop: '0' }
        "
        [ngClass]="{ 'treatment-information-cat': specieSelected === 2 }"
      >
        <div class="information-title">
          @if (specieSelected === 1) {
          <p class="goal-weight"><i class="bi bi-trophy"></i> Peso meta:</p>
          } @else {
          <p class="goal-weight">
            <img
              class="icon-obesity-treatment__cat"
              [src]="iconObesityTreatmentCat"
              alt="Balança de peso"
            />
            Redução de Peso
          </p>
          }
          <p>Duração do tratamento:</p>
          @if (specieSelected === 1) {
          <p>Peso a ser eliminado:</p>
          }
          <p>Calorias diárias:</p>
        </div>
        <div class="block-return-treatment-info">
          <p
            *ngIf="obesityTreatment.previousTreatmentDurationInMonths"
            class="info-return-treatment"
          >
            {{ obesityTreatment.previousTreatmentDurationInMonths }}
            {{
              obesityTreatment.previousTreatmentDurationInMonths === 1
                ? "mês"
                : "meses"
            }}
            <i
              [style.left]="
                obesityTreatment.previousTreatmentDurationInMonths === 1
                  ? '12px'
                  : '0px'
              "
              class="bi bi-arrow-right-short"
            ></i>
          </p>
          <p
            *ngIf="
              obesityTreatment.previousWeightToLose && specieSelected === 1
            "
            class="info-return-treatment"
          >
            {{ obesityTreatment.previousWeightToLose | number : "1.3-3" }} kg
            <i class="bi bi-arrow-right-short"></i>
          </p>
          <p
            *ngIf="obesityTreatment.previousDailyKcal"
            class="info-return-treatment"
          >
            {{ obesityTreatment.previousDailyKcal }} kcal
            <i class="bi bi-arrow-right-short"></i>
          </p>
        </div>

        <div class="information-data">
          @if (specieSelected === 1) {
          <p>{{ obesityTreatment.targetWeight | number : "1.3-3" }} Kg</p>
          }

          <p>
            {{ obesityTreatment.treatmentDurationInMonths }}
            {{
              obesityTreatment.treatmentDurationInMonths === 1 ? "mês" : "meses"
            }}
          </p>

          @if (specieSelected === 1) {

          <p>{{ obesityTreatment.weightToLose | number : "1.3-3" }} Kg</p>
          }

          <p>{{ obesityTreatment.dailyKcal }} Kcal</p>
        </div>
      </div>
      } @else {
      <div class="loading-container__obesity-treatment">
        <ngx-skeleton-loader
          animation="progress-dark"
          [count]="specieSelected === 1 ? 4 : 3"
        />
      </div>
      }
     }
    </div>
    } } @if (obesityTreatment) {
    <hr />
    <section class="container-treatment-table">
      <button type="button" class="btn btn-toggle" (click)="toggleTable()">
        <span class="title-btn">{{ titleButtonToggle }}</span>
        <i
          [ngClass]="openTable ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"
        ></i>
      </button>
      @if (openTable) {
      <div class="content-treatment-table">
        @if(!showLoading) {
        <app-generic-table
          [headerTable]="headerTable"
          [dataTable]="contentTable"
        >
        </app-generic-table>
        } @else {

        <div class="loading-container__obesity-treatment m-0">
          <ngx-skeleton-loader
            animation="progress-dark"
            appearance="custom-content"
          />
        </div>
        }
      </div>
      }
    </section>
    }
  </div>

  } @else {

  <section
    [ngStyle]="obesityTreatment ? { padding: '0.5rem' } : { padding: '1rem' }"
    [class.pt-0]="obesityTreatment?.isReturn"
    class="description-container__mobile"
  >
    @if (scoreSelected) { @if (obesityTreatment?.isReturn) {
    <div class="return-tag">Retorno</div>
    }
    <div class="description-content__mobile">
      <h6
        [ngStyle]="
          IsReturnObesityTreatmentWithCurrentWeight
            ? { paddingTop: '0.5rem' }
            : {}
        "
        class="title-description"
      >
        {{ scoreSelected.order }} - {{ capitalizeString(scoreSelected.name) }}
      </h6>
      <p class="description-detail">{{ scoreSelected.detail }}</p>
      <img
        class="pet-condition__mobile"
        [src]="scoreSelected.url"
        alt="Condição do Pet"
      />
      <p class="description-text">{{ scoreSelected.description }}</p>
    </div>
    } @if (obesityTreatment) {
    <hr />
    @if(!showLoading) {

    <div class="treatment-information">
      <div class="information-title">
        <div class="d-flex justify-content-between">
          @if (specieSelected === 1) {
          <p class="goal-weight"><i class="bi bi-trophy"></i> Peso meta:</p>
          } @else {
          <p class="goal-weight">
            <img
              class="icon-obesity-treatment__cat"
              [src]="iconObesityTreatmentCat"
              alt="Balança de peso"
            />
            Redução de Peso
          </p>
          } @if (obesityTreatment.targetWeight) {

          <p class="goal-weight__value">
            {{ obesityTreatment.targetWeight | number : "1.3-3" }} kg
          </p>
          } @if(!obesityTreatment?.targetWeight && specieSelected == 1) {

          <p class="goal-weight__value">-</p>
          }
        </div>
        <div class="d-flex justify-content-between">
          <p
            [class.return-treatment-mobile]="
              IsReturnObesityTreatmentWithCurrentWeight
            "
          >
            Duração do tratamento:
          </p>

          @if (obesityTreatment.treatmentDurationInMonths ) {
          <div class="block-return-treatment-info right-move">
            <p
              *ngIf="obesityTreatment.previousTreatmentDurationInMonths"
              class="info-return-treatment"
            >
              {{ obesityTreatment.previousTreatmentDurationInMonths }}
              {{
                obesityTreatment.previousTreatmentDurationInMonths === 1
                  ? "mês"
                  : "meses"
              }}
              <i
                [style.left]="
                  obesityTreatment.previousTreatmentDurationInMonths === 1
                    ? '12px'
                    : '0px'
                "
                class="bi bi-arrow-right-short"
              ></i>
            </p>
          </div>
          <strong>
            <span
              [innerHTML]="obesityTreatment.treatmentDurationInMonths"
            ></span>
            <span>{{
              obesityTreatment.treatmentDurationInMonths === 1
                ? " mês"
                : " meses"
            }}</span>
          </strong>
          } @else {
          <strong>-</strong>
          }
        </div>
        @if (specieSelected === 1) {
        <div class="d-flex justify-content-between">
          <p
            [class.return-treatment-mobile]="
              IsReturnObesityTreatmentWithCurrentWeight
            "
          >
            Peso a ser eliminado:
          </p>
          @if ( obesityTreatment.weightToLose ) {
          <div class="block-return-treatment-info">
            <p
              *ngIf="obesityTreatment.previousWeightToLose"
              class="info-return-treatment"
            >
              {{ obesityTreatment.previousWeightToLose | number : "1.3-3" }} kg
              <i class="bi bi-arrow-right-short"></i>
            </p>
          </div>
          <strong
            >{{ obesityTreatment.weightToLose | number : "1.3-3" }} kg</strong
          >
          } @else {
          <strong>-</strong>
          }
        </div>
        }

        <div class="d-flex justify-content-between">
          <p
            [class.return-treatment-mobile]="
              IsReturnObesityTreatmentWithCurrentWeight
            "
          >
            Calorias diárias:
          </p>
          @if (obesityTreatment?.dailyKcal ) {
          <div class="block-return-treatment-info">
            <p
              *ngIf="obesityTreatment.previousDailyKcal"
              class="info-return-treatment"
            >
              {{ obesityTreatment.previousDailyKcal }} kcal
              <i class="bi bi-arrow-right-short"></i>
            </p>
          </div>
          <strong>{{ obesityTreatment.dailyKcal }} kcal</strong>
          } @else {
          <strong>-</strong>
          }
        </div>
      </div>
    </div>
    } @else {
    <div class="loading-container__obesity-treatment">
      <ngx-skeleton-loader
        animation="progress-dark"
        [count]="specieSelected === 1 ? 4 : 3"
      />
    </div>
    } } @if (obesityTreatment) {
    <section class="container-treatment-table">
      <button type="button" class="btn btn-toggle" (click)="toggleTable()">
        <span class="title-btn">{{ titleButtonToggle }}</span>
        <i
          [ngClass]="openTable ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"
        ></i>
      </button>
      @if (openTable) {
      <div class="content-treatment-table">
        @if(!showLoading) {

        <app-generic-table
          [headerTable]="headerTable"
          [dataTable]="contentTable"
        />
        } @else {
        <div class="loading-container__obesity-treatment">
          <ngx-skeleton-loader
            animation="progress-dark"
            appearance="custom-content"
          />
        </div>
        }
      </div>
      }
    </section>
    }
  </section>
  <section class="container-swiper">
    <app-swiper-score-mobile
      (currentStepEmitter)="getScoreMobileSelected($event)"
      [numbers]="scoreListMobile"
    />
  </section>

  }

  <cite class="source-about-score">
    ADAPTADO DE: Laflame, D.P., 1997; Laflame, D.P., 2006
  </cite>
  }
</div>
<!--Foi necessário colocar o ng-template aqui pois o mesmo não apresentava o comportamento esperado dentro da sequencia de ifs proximo ao app-generic-table-->
<ng-template let-data #realWeightLoss>
  <span [class]="getWeightDifference(data.realWeightLoss)">{{ data.realWeightLoss }}</span>
</ng-template>
