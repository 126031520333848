import {
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }

  static validatePhoneDdd(ddd: string) {
    const dddList = ['11','12','13','14','15','16','17','18','19','21',
      '22','24','27','28','31','32','33','34','35','37',
      '38','41','42','43','44','45','46','47','48','49',
      '51','53','54','55','61','62','63','64','65','66',
      '67','68','69','71','73','74','75','77','79','81',
      '82','83','84','85','86','87','88','89','91','92',
      '93','94','95','96','97','98','99'];

    if(ddd != null && ddd.length >= 2){
      let checkDdd = dddList.find((x) => {return x === ddd});
      if (checkDdd != null) {
        return true;
      }
    }

    return false;
  }

  static validateCNPJ = (cnpj: string) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (!cnpj) {
      return false;
    }

    if (cnpj.length !== 14) {
      return false;
    }

    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }

    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let addition = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      addition += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = addition % 11 < 2 ? 0 : 11 - (addition % 11);
    if (result != parseInt(digits.charAt(0))) return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    addition = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      addition += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    result = addition % 11 < 2 ? 0 : 11 - (addition % 11);
    if (result != parseInt(digits.charAt(1))) return false;

    return true;
  };
}
