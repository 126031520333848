export enum PhysicalActivityEnum {
  LOW_ACTIVITY = 1,
  ACTIVE = 2,
  HIGH_ACTIVITY = 3,
  WEIGHT_MAINTENANCE = 4,
  WEIGHT_REDUCTION = 5,
  WEIGHT_REDUCTION_RETURN = 9,
  NA = 6
}

