<div #selectRef class="select-tags">
  <label
    [class.error]="required && !optionsSelected.length && options != null && (optionsTouched || formSubmitted)"
    [for]="label">{{ label }}<span *ngIf="required" class="required">*</span></label>
  <div style="position: relative">
    <div
      [class.error]="required && !optionsSelected.length && options != null && (optionsTouched || formSubmitted)"
      class="tags">
      <div class="tag-container">
        <span *ngIf="!optionsSelected.length" class="placeholder"> {{ placeholder }}</span>
        <div [style]="'background-color:' + tagColor" *ngFor="let tag of optionsSelected" class="tag">
          <span [style]="'color:' + textColor">{{ tag[optionTitle] }}</span>
          <button type="button" (click)="removeTag(tag)">
            <i [style]="'color:' + textColor" class="bi bi-x"></i>
          </button>
        </div>
      </div>
      <button class="expand-icon" *ngIf="options?.length > 0">
        <span class="material-symbols-outlined">
          expand_more
        </span>
      </button>
    </div>
    <span *ngIf="required && !optionsSelected.length && options != null && (optionsTouched || formSubmitted)" class="span-error">{{ errorMessage }}</span>

    <select
      [style.top]="'0px'"
      [disabled]="disabled"
      [style]="'width:' + width"
      [id]="label"
      [(ngModel)]="selectedOption"
      (ngModelChange)="onOptionSelected($event, true)">
      <option [style.display]="options?.length ? 'none' : 'block'" [disabled]="!options?.length" [ngValue]="null"> {{ options?.length ? 'Selecione' : 'Nenhuma opção encontrada' }}</option>
      <option *ngFor="let option of options" [ngValue]="option">{{ option[optionTitle] }}</option>
    </select>
  </div>
</div>
