<ng-container >
  <section class="container-swiper">
    <swiper #swiper [config]="config"
    class="content-swiper">
      @for (item of numbers; track $index) {
        <ng-template swiperSlide>
          <div class="swiper-item">
            {{ item }}
          </div>
        </ng-template>
        }
      </swiper>
  </section>
</ng-container>



