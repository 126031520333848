import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any, searchTerm: any, filterKey: any): any{
    const filteredList: any = [];
    if (searchTerm){
      const newSearchTerm = !isNaN(searchTerm) ? searchTerm.toString() : searchTerm.toString().toUpperCase();
      let prop;
      return items.filter((item: any) => {
        for (const key in item) {
          prop = isNaN(item[filterKey]) ? item[filterKey].toString().toUpperCase() : item[filterKey].toString();
          if (prop.indexOf(newSearchTerm) > -1) {
            filteredList.push(item);
            return filteredList;
          }
        }
      });
    }
    else{
      return items;
    }
  }
}
