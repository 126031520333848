export interface PetPhysicalActivityModel {
  id: number;
  name: string;
  description?: string;
}

export interface PetPhysicalActivityResponse {
  title: string;
  physicalActivities: PetPhysicalActivityModel[];
}

export const PetPhysicalActivityMaximumTreamentPeriod = [
  {
    id: 4,
    name: "Manutenção do peso"
  },
  {
    id: 5,
    name: "Iniciar Tratamento",
    description:"Redução do peso"
  }
]
