import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AcceptedTermsResponse } from './models/accepted-terms-response';
import { AcceptTermsRequest } from './models/accept-terms-request';
import { LoadingService } from '../shared/loading.service';

@Injectable({
  providedIn: 'root'
})
export class TermsResponsabilityService {
  constructor(private http: HttpClient, private loadingService: LoadingService) { }
  getAcceptedTerms(email: string, phone: string): Observable<AcceptedTermsResponse>{
    const headers = this.loadingService.getLoadingHeader();
    const url = `${environment.baseApimUrl}/termsResponsibility/accepted/${email}/${phone}`;
    return this.http.get<AcceptedTermsResponse>(url, {
      headers
    });
  }

  postAcceptTerms(request: AcceptTermsRequest): Observable<AcceptedTermsResponse>{
    const url = `${environment.baseApimUrl}/termsResponsibility/accept`;
    return this.http.post<AcceptedTermsResponse>(url, request);
  }
}
