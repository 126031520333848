import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EmailVerificationModel } from 'src/app/services/authentication/models/email-verification-model';

@Component({
  selector: 'app-confirm-email-message',
  templateUrl: './confirm-email-message.component.html',
  styleUrls: ['./confirm-email-message.component.scss'],
})
export class ConfirmEmailMessageComponent implements OnInit {
  @Input() email: string;
  @Input() type: 'register' | 'changePassword';
  token: string = '';
  sendSuccessfully: boolean = false;
  constructor(
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  resendEmail() {
    this.spinner.show();

    const validateRequest: EmailVerificationModel = {
      verificationToken: this.token,
      email: this.email,
    };

    if (this.type == 'register') {
      this.authenticationService.verificationEmail(validateRequest).subscribe(

        () => {
          this.toastrService.success(
            'E-mail enviado com sucesso. Verifique seu spam.'
          );
          this.sendSuccessfully = true;
          localStorage.removeItem('registrationForm');
          this.spinner.hide();
        },
        (error) => {
          this.toastrService.error('Token invalido.', 'Erro');
        },
      );
    }

    if (this.type == 'changePassword') {
      this.authenticationService.requestChangePassword(this.email).subscribe(
        () => {
          this.toastrService.success(
            'E-mail enviado com sucesso. Verifique seu spam.'
          );
          this.sendSuccessfully = true;
        },
        (error) => {
          this.toastrService.error('Token invalido.', 'Erro');
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }
}
