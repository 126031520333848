import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-generic-tip',
  templateUrl: './generic-tip.component.html',
  styleUrls: ['./generic-tip.component.scss']
})
export class GenericTipComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() tipText: string;
  constructor() { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.close.emit(true);
  }
}
