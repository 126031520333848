<div class="cards-filters" (click)="applyFilter()" [class.selected]="selected">
  <div class="content-filters">
    <img class="icon_filter" [src]="icon" alt="icon" />

    <div class="info-filters-container">
      <label class="type-filter">{{ titleCard }}</label>
      <span class="qtd-filter">{{ totalData }}</span>
    </div>
  </div>
</div>
