<div class="credentials-container">
  <section [ngClass]="mobileScreen? 'wrapper-mobile':'wrapper' ">
    <button class="button-back-mobile" *ngIf="mobileScreen && !hideMobileBackButton" (click)="getNavigate()"><i
      class="bi bi-arrow-left"></i></button>
  </section>

  <section [ngClass]="mobileScreen?'form-mobile':'form'">
    <ng-content></ng-content>
    <button *ngIf="!mobileScreen" class="button-back" (click)="getNavigate()">
      <i class="bi bi-x-lg icon-close" tooltip="Voltar"></i>
    </button>
  </section>
</div>
