<div class="container-calendar">
  <div class="header-select-period">
    <h2 class="title">Selecionar período</h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="onCloseModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="time-options">
    <button
      [class.highlight]="!selectedRangeStartDate && !selectedRangeEndDate"
      (click)="getAllPeriod()"
    >
      Todo período
    </button>
    <button [class.highlight]="isDayDifference(7)" (click)="getLast7Days()">
      Últimos 7 dias
    </button>
    <button [class.highlight]="isDayDifference(30)" (click)="getLast30Days()">
      Últimos 30 dias
    </button>
    <button [class.highlight]="isBetweenLastYear()" (click)="getLastYear()">
      Ano passado
    </button>
  </div>

  <div class="calendars">
      <div class="calendar" *ngFor="let month of monthsList">
        <h3 class="month-title">
          <h3 class="month-title">
            {{ formatMonthTitle(month) }}
          </h3>
        </h3>
        <app-calendar-view
          [month]="month.getMonth()"
          [year]="month.getFullYear()"
          [currentDate]="currentDate"
          [selectedDates]="selectedDates"
          [selectedRangeStartDate]="selectedRangeStartDate"
          [selectedRangeEndDate]="selectedRangeEndDate"
          [selectingStartDate]="selectingStartDate"
          (dateSelected)="onSelectDate($event)"
        ></app-calendar-view>
      </div>
  </div>

  <div class="container-apply-period">
    <span class="select-date"
      ><ng-container
        *ngIf="selectedRangeStartDate && selectedRangeEndDate; else selectDate"
      >
        {{ formatDate(selectedRangeStartDate) }} -
        {{ formatDate(selectedRangeEndDate) }}
      </ng-container>
      <ng-template #selectDate>Selecione uma data</ng-template>
    </span>
    <button class="apply-period" (click)="applySelectedRange()">
      Aplicar período
    </button>
  </div>
</div>
