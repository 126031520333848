@if (!mobileScreen) {
<div class="modal-container">
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Fechar"
      (click)="closeModal()"
    >
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>
  <div class="modal-body">
    @switch (currentTemplate) { @case ('firstTemplate') {
    <section #firstSlide>
      <div class="container-img">
        <img
          src="../../../../../assets/images/popup/banner-2.png"
          alt="imagem ilustrativa do tratamento de obesidade"
        />
      </div>
      <div class="modal-container">
        <div class="d-flex justify-content-between">
          <p class="container-title">
            Tratamento de Redução de Peso: como aplicar?
          </p>
          <span class="list-pagination">1/3</span>
        </div>
        <p class="container-description">
          Após definir a “Finalidade da Orientação” como “Obesidade”, selecione
          em “Objetivo do Tratamento” a opção
          <strong>“Redução de Peso - Iniciar Tratamento” </strong> para começar
          um novo tratamento do pet, ou
          <strong>“Redução de Peso - Retorno”</strong>
          para realizar o acompanhamento mensal do paciente.
        </p>
      </div>
    </section>

    } @case ('secondTemplate') {

    <section #secondSlide>
      <div class="container-img">
        <img
          src="../../../../../assets/images/popup/banner-3.png"
          alt="imagem ilustrativa do tratamento de obesidade"
        />
      </div>
      <div class="modal-container">
        <div class="d-flex justify-content-between">
          <p class="container-title">Como fazer o acompanhamento do pet?</p>
          <span class="list-pagination">2/3</span>
        </div>
        <p class="container-description">
          Monitore mensalmente o escore corporal e o peso do seu paciente. Crie
          orientações de Retorno a cada atualização de peso para que, se
          necessário, o tempo de tratamento e a ingestão diária de alimento
          sejam recalculadas.
        </p>
      </div>
    </section>
    } @case ('thirdTemplate') {
    <section #thirdSlide>
      <div class="container-img">
        <img
          src="../../../../../assets/images/popup/banner-4.png"
          alt="imagem ilustrativa do tratamento de obesidade"
        />
      </div>
      <div class="modal-container">
        <div class="d-flex justify-content-between">
          <p class="container-title">
            Como verificar o progresso do tratamento?
          </p>
          <span class="list-pagination">3/3</span>
        </div>
        <p class="container-description">
          Acompanhe o progresso da perda de peso do início ao fim do tratamento
          informando apenas o peso do pet. A PremieRvet® calculará
          automaticamente para você: o tempo de tratamento, a quantidade diária
          de alimento e o peso a ser perdido.
        </p>
      </div>
    </section>
    } }
    <div class="modal-actions">
      <div class="container-checkbox">
        <input
          [(ngModel)]="isChecked"
          (change)="setInfoLocalStorage($event)"
          type="checkbox"
        />
        <label>Não mostrar novamente</label>
      </div>

      <div class="containers-buttons">
        <button
          class="button"
          [class.disabled]="currentTemplate === 'firstTemplate'"
          (click)="previousSlide()"
        >
          Anterior
        </button>
        <button
          class="button"
          [class.disabled]="currentTemplate === 'thirdTemplate'"
          (click)="nextSlide()"
        >
          Próximo
        </button>
      </div>
    </div>
  </div>
</div>
} @else {
<section #slidesMobile class="container-slides-mobile">
  <ng-container style="display: block">
    <swiper [config]="config" class="swiper">
      @for (slide of listSlides; track $index) {
      <ng-template swiperSlide>
        <div class="swiper-slide-container">

            <img class="img-mobile" [src]="slide.img" />


          <p class="title-mobile" [innerHTML]="slide.title"></p>
          <p class="description-mobile" [innerHTML]="slide.description"></p>
          <p class="description-mobile mt-3" [innerHTML]="slide.secondDescription"></p>
          <div class="container-button fixed-bottom">
            <button class="btn button-not-show-again" (click)="setNotViewMoreMobile()">Não mostrar novamente</button>
          </div>
          </div>
      </ng-template>

      }
    </swiper>
  </ng-container>

</section>
@defer (on timer(200ms)) {

<button (click)="closeModal()" class="btn close-modal">Fechar</button>
} }
