<img
  *ngIf="!profilePhoto"
  class="icon-user"
  src="../../../../../assets/images/empty.png"
  alt="Sem foto de perfil"
/>

<img
  *ngIf="profilePhoto"
  class="profile-photo"
  [src]="profilePhoto"
  alt="Foto de perfil"
/>
