import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-user',
  templateUrl: './avatar-user.component.html',
  styleUrls: ['./avatar-user.component.scss'],
})
export class AvatarUserComponent implements OnInit {
  profilePhoto: string = '';

  constructor() {}

  ngOnInit(): void {
    this.profilePhoto = localStorage.getItem('profilePhoto');
  }
}
