  <app-background
urlNavigation="/#/login">
  <form id="id-recover-password-form" *ngIf="!requestChangeSuccessfully" [formGroup]="recoverPasswordForm" class="form">
    <h5 class="form-title">Esqueceu sua senha?</h5>
      <p class="form-description">Confirme seu e-mail para o envio do link de recuperação.</p>
      <div>
        <field-validate fieldName="E-mail">
          <input
            #email
            type="text"
            id="email"
            formControlName="email"
            placeholder="Informe seu email"
            />
        </field-validate>
      </div>

    <button  type="submit" id="id-recover-password-btn-submit" class="btn-submit" (click)="onSubmit()">Enviar link</button>
  </form>

  <section *ngIf="requestChangeSuccessfully">
    <app-confirm-email-message
    [email]="recoverPasswordForm.controls['email'].value"
    type="changePassword">
    </app-confirm-email-message>
  </section>
</app-background>
