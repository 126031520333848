import { Injectable } from '@angular/core';
import { DefaultHttpClient, HttpRequest, HttpResponse, HubConnection } from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import * as signalR from '@aspnet/signalr';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import {SignalRConnectionInfo} from './models/signalRConnectionInfo.model';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class SignalRService {
    private hubConnection: HubConnection;
    messages: Subject<string> = new Subject();

    constructor(private authService: AuthenticationService, private httpClient: HttpClient) {

    }

  private getConnectionInfo(): Observable<SignalRConnectionInfo> {
    return this.httpClient.post<SignalRConnectionInfo>(`${environment.baseApimUrl}/negotiate`, { });
  }

    init(): void {
      this.getConnectionInfo().subscribe((info) => {
        const options = {
          accessTokenFactory: () => info.accessToken,
        };

        this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(info.url, options)
          .configureLogging(signalR.LogLevel.Information)
          .build();

        this.hubConnection.start().catch((err) => console.error(err.toString()));

        this.hubConnection.on('notifications', (data: any) => {
            this.messages.next(data);
        });
      });
    }
}
