import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Periods } from 'src/app/services/static/models/periods';
import { States } from 'src/app/services/static/models/states';
import { Universities } from 'src/app/services/static/models/universities';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StaticService {
  constructor(private http: HttpClient) { }

  private readonly urlStatic = `${environment.baseApimUrl}/static`;

  getAllAges(): Observable<number[]>{
    const url = this.urlStatic + '/petages';
    return this.http.get<number[]>(url);
  }

  getAllMonths(): Observable<Periods[]>{
    const url = this.urlStatic + '/monthsOfYear';
    return this.http.get<Periods[]>(url);
  }

  getAllUniversities(): Observable<Universities[]>{
    const url = this.urlStatic + '/universities';
    return this.http.get<Universities[]>(url);
  }

  getAllPeriods(): Observable<Periods[]>{
    const url = this.urlStatic + '/periods';
    return this.http.get<Periods[]>(url);
  }

  getAllStatesOfBrazil(): Observable<States[]>{
    const url = this.urlStatic + '/allStatesOfBrazil';
    return this.http.get<States[]>(url);
  }
}
