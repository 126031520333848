import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppListResponse } from '../shared/app.response';
import { BreedModel, BreedSizeModel } from './models/breed-model';
import { LookupBaseModel } from './models/lookup-base-model';
import { SizeModel } from './models/size-model';
import { AuthenticationService } from '../authentication/authentication.service';
import { map } from 'rxjs/operators';
import { SizeEnum } from '../../modules/shared/enums/size.enum';
import { SpecialtyEnum } from '../../modules/shared/enums/specialty.enum';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}
  private apiUrl = `${environment.baseApimUrl}/lookup`;
  private readonly cidadesUrl = `${environment.baseApimUrl}/cidades`;
  private readonly apiURLBreeds = `${environment.apiCalculator}/breed`;
  private readonly apiURLClinic = `${environment.baseApimUrl}/clinica`;

  getAllSizes(): Observable<AppListResponse<SizeModel>> {
    const url = this.apiUrl + '/porte/items';
    const searchobject = {
      Filters: [
        {
          Key: 'nome',
          Operator: 'NotEquals',
          Value: '',
        },
      ],
      SortField: 'id',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 99,
    };
    return this.http.post<AppListResponse<SizeModel>>(url, searchobject);
  }

  getBreeds(specieId?: number): Observable<AppListResponse<BreedModel>> {
    const url = this.apiURLBreeds + '/list';
    const searchObject = {
      SearchExpression: '',
      Filters: [
        {
          Key: 'name',
          Operator: 'NotEquals',
          Value: '',
        },
        {
          Key: 'isActive',
          Operator: 'Equals',
          Value: true,
        }
      ],
      SortField: 'name',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 999,
    };

    if (specieId) {
      searchObject.Filters = [
        {
          Key: 'Specie',
          Operator: 'Equals',
          Value: String(specieId),
        },
        {
          Key: 'isActive',
          Operator: 'Equals',
          Value: true,
        },
      ];
    }
    return this.http.post<AppListResponse<BreedModel>>(url, searchObject).pipe(
      map((response => {
        return {
          ...response,
          items: this.changeBreedOrder(response.items)
        };
      }))
    );
  }

  getSpecies(): Observable<AppListResponse<LookupBaseModel>> {
    const url = this.apiUrl + '/especie/items';
    const searchObject = {
      Filters: [
        {
          Key: 'nome',
          Operator: 'NotEquals',
          Value: '',
        },
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 99,
    };
    return this.http.post<AppListResponse<LookupBaseModel>>(url, searchObject);
  }

  getPhisicalActivities(): Observable<AppListResponse<LookupBaseModel>> {
    const url = `${this.apiUrl}/petCalculatorActivityLevel/items`;
    const searchObject = {
      Filters: [
        {
          Key: 'nome',
          Operator: 'NotEquals',
          Value: '',
        },
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 99,
    };

    return this.http.post<AppListResponse<LookupBaseModel>>(url, searchObject);
  }

  getCity(id: number): Observable<LookupBaseModel> {
    const url = `${this.cidadesUrl}/${id}`;

    return this.http.get<LookupBaseModel>(url);
  }

  getCities(state: string): Observable<AppListResponse<LookupBaseModel>> {
    const url = `${this.cidadesUrl}/list`;

    const searchObject = {
      Filters: [
        {
          Key: 'uf',
          Operator: 'Equals',
          Value: state,
        },
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 9999,
    };

    return this.http.post<AppListResponse<LookupBaseModel>>(url, searchObject);
  }

  getSpecialties(): Observable<AppListResponse<LookupBaseModel>> {
    const url = this.apiUrl + '/especialidade/items';
    const searchObject = {
      Filters: [
        {
          Key: 'nome',
          Operator: 'NotEquals',
          Value: '',
        },
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 99,
    };
    return this.http.post<AppListResponse<LookupBaseModel>>(url, searchObject).pipe(
      map(res => {
        return {
          ...res,
          items: res.items.sort((a, b) => {
            if (a.id === SpecialtyEnum.OTHERS) { return 1; }
            if (b.id === SpecialtyEnum.OTHERS) { return -1; }
            return a.text.localeCompare(b.text);
          })
        };
      })
    );
  }

  private changeBreedOrder(breeds: BreedModel[]): BreedModel[] {
    const updatedBreeds = breeds.map(b => ({
      ...b,
      breedSizesModel: this.changeSizesOrder(b.breedSizesModel)
    }));
    const lastItems = updatedBreeds.filter(breed => breed.name.startsWith('SRD') || breed.name === 'Outros');
    const uniqueBreeds = updatedBreeds.filter((thing, i, arr) => arr.findIndex(t => t.name === thing.name) === i);
    const filteredBreeds = uniqueBreeds.filter(breed => !lastItems.includes(breed));

    return [...filteredBreeds, ...lastItems];
  }

  private changeSizesOrder(sizes: BreedSizeModel[]): BreedSizeModel[] {
    const sizeResponse: BreedSizeModel[] = [];
    const orderedSize = this.getOrderedSizeList();
    const sizeIds = sizes.map(x => x.size as SizeEnum);

    orderedSize.filter(x => sizeIds.includes(x)).forEach(size => {
      const sizeModel = sizes.find(s => s.size === size);
      sizeResponse.push(sizeModel);
    });
    return sizeResponse;
  }

  private getOrderedSizeList(): SizeEnum[] {
    const enumOrdered = [
      SizeEnum.MINI,
      SizeEnum.SMALL,
      SizeEnum.MEDIUM,
      SizeEnum.BIG,
      SizeEnum.GIANT,
      SizeEnum.NA,
    ];
    Object.values(SizeEnum).forEach(item => {
      const itemEnum = item as SizeEnum;
      const exists = enumOrdered.includes(itemEnum);
      if (!exists){
        enumOrdered.push(itemEnum);
      }
    });
    return enumOrdered;
  }
}
