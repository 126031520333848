<div class="modal-container">
  <div class="background-mobile">
    <img [src]="backgroundMobile" alt="imagem de fundo">
  </div>
  <div class="modal-header">
    <h4 class="modal-title">Nenhum WhatsApp cadastrado para o tutor :(</h4>
    <button type="button" class="close pull-right" aria-label="Fechar" (click)="closeModal()">
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>Ainda não existe nenhum número de WhatsApp cadastrado para este tutor. Que tal cadastrar agora e enviar a orientação pelo WhatsApp?</p>
    <form [formGroup]="tutorForm">
      <field-validate
        fieldName="WhatsApp do tutor"
        customErrorMessage="Informe um número válido"
        [showSuccessBackground]="false"
        [showRequiredAsteristic]="false">
        <input
          type="text"
          mask="(00) 00000-0000||(00) 0000-0000"
          formControlName="celphone"
          placeholder="(00) 00000-0000"
        />
      </field-validate>
    </form>

    <div class="buttons-container">
      <button class="button button-link" (click)="closeModal()">
        Cancelar
      </button>
      <button class="button button-primary" (click)="updateTutorNumber()">Cadastrar</button>
    </div>
  </div>
</div>
