<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li
      *ngFor="let item of breadcrumbs"
      class="breadcrumb-item"
      aria-current="page"
    >
      <a
        routerLink="{{ item.url }}"
        [ngClass]="item.last ? 'disabled': 'bold'"
      >
        {{ item.name }}
      </a>
    </li>
  </ol>
</nav>
