import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private logService: LoggingService) {
    super();
  }

  handleError(error: Error): void {
    this.logService.logException(error);
  }
}
