import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

let columnSortable: any[] = [];

const expandAnimation = trigger('expandAnimation', [
  state(
    'true',
    style({
      height: '*',
    })
  ),
  state(
    '*',
    style({
      height: '0px',
    })
  ),
  transition('* <=> true', animate('300ms cubic-bezier(.2,0,0.02,1)')),
]);

@Component({
  selector: 'app-table, p-table',
  animations: [expandAnimation],
  template: `
    <table
      class="p-table"
      [class.table-text-centered]="pTableCenteredText"
      [class.no-lines]="pTableNoLines"
    >
      <thead
        [class]="
          pTableHeaderClass +
          ' ' +
          (pTableHeaderFixed
            ? 'p-table-header p-table-header-sticky'
            : 'p-table-header')
        "
        [class.small]="pTableHeaderSmall"
        [class.th-break]="pTableThBreak"
      >
        <tr>
          <ng-content></ng-content>
        </tr>
      </thead>
      <tbody class="p-table-body" [class.td-break]="pTableTdBreak">
        <ng-container
          class="p-table-emphasis"
          *ngFor="let item of actualTableData.content"
        >
          <tr
            [style.background]="
              item.haveEmphasis || item.selected
                ? item.errorEmphasis
                  ? 'rgba(255,0,0,0.15)'
                  : '#FFEBD4'
                : ''
            "
            (click)="
              pTableSelect && !item.selectDisabled
                ? selectInTable(item)
                : false;
              onRowClick(item)
            "
          >
            <td *ngFor="let header of actualTableData.header">
              <ng-container
                *ngIf="header.cellTemplate !== undefined"
                [ngTemplateOutlet]="header.cellTemplate"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-container>
              {{
                header.cellTemplate !== undefined
                  ? ''
                  : getPropByString(item, header.prop)
              }}
            </td>
          </tr>
          <tr class="table-expanded-row" *ngIf="pTableExpands">
            <td [colSpan]="colSpanSet()">
              <div
                class="table-expanded-content"
                *ngIf="item.expanded"
                [@expandAnimation]="item.expanded"
              >
                <ng-container
                  *ngIf="tableExpandRow"
                  [ngTemplateOutlet]="tableExpandRow.expandedRowTemplate"
                  [ngTemplateOutletContext]="{ $implicit: item }"
                ></ng-container>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot
        [class]="
          pTableFooterFixed
            ? 'p-table-footer p-table-footer-sticky'
            : 'p-table-footer'
        "
      >
        <ng-content select="[table-footer]"></ng-content>
      </tfoot>
    </table>
  `,
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pTableHeaderSmall: boolean;
  @Input() pTableHeaderClass: string;
  @Input() pTableNoLines: boolean;
  @Input() pTableCenteredText: boolean;
  @Input() pTableExpands: boolean;
  @Input() pTableSelect: boolean;
  @Input() pTableTdBreak: boolean;
  @Input() pTableThBreak: boolean;
  @Input() pTableSelectMode: TableSelectMode = 'single';
  @Input() pTableHeaderFixed: boolean;
  @Input() pTableFooterFixed: boolean;
  @Input() hasFooter: boolean;
  @Input() haveEmphasis: boolean;

  @Input() pTableData: any[] | any = [];

  @Input() selectedData: any | any[];
  @Output() selectedDataChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectAllChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  actualTableData: TableData = new TableData();

  @ContentChildren(forwardRef(() => TableColumnComponent))
  tableColumns: QueryList<TableColumnComponent>;

  @ContentChild(forwardRef(() => TableExpandedRowComponent))
  tableExpandRow: TableExpandedRowComponent;

  areAllSelected: boolean;

  selectedOptions: any[] = [];

  constructor(private detectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.pTableData !== undefined) {
      if (!Array.isArray(this.pTableData)) {
        this.pTableData = [this.pTableData];
      }
      if (this.pTableData.length > 0) {
        setTimeout(() => {
          this.configureTable();
        }, 0);
      }
    }
  }

  public ngOnChanges(event: SimpleChanges): void {
    if (event.pTableData !== undefined) {
      if (!event.pTableData.firstChange) {
        if (!Array.isArray(this.pTableData)) {
          this.pTableData = [this.pTableData];
        }
        setTimeout(() => {
          this.detectorRef.detectChanges();
          this.configureTable();
        }, 0);
      }
    }
    if (event.pTableSelect !== undefined) {
      if (!event.pTableSelect.firstChange) {
        if (event.pTableSelect.currentValue === false) {
          this.selectItems();
        }
      }
    }
  }

  public onRowClick(rowItem: any): void {
    const itemCopy = Object.assign({}, rowItem);
    delete itemCopy.selected;
    delete itemCopy.originalIdx;

    this.rowClick.emit(itemCopy);
  }

  public configureTable(): void {
    if (this.tableColumns !== undefined) {
      const hdrArr: TableDataHeader[] = [];
      this.tableColumns.toArray().forEach((v) => {
        const obj: TableDataHeader = {
          name: v.columnName,
          cellTemplate: v.columnCellTemplate,
          prop: v.columnProp,
        };
        hdrArr.push(obj);
      });
      this.actualTableData.header = hdrArr;
    }
    const contArr: any[] = [];
    let i = 0;
    for (; i < this.pTableData.length; i++) {
      const keys = Object.keys(this.pTableData[i]);
      const obj: any = {};
      keys.forEach((v) => {
        obj[v] = this.pTableData[i][v];
      });
      if (this.pTableSelect) {
        obj.selected = false;
      }
      if (this.haveEmphasis) {
        obj.haveEmphasis = false;
      }
      if (this.pTableExpands) {
        obj.expanded = false;
      }
      contArr.push(obj);
    }
    contArr.forEach((x, y) => {
      x.originalIdx = y;
    });
    this.actualTableData.content = contArr;
    if (this.pTableSelect) {
      this.selectItems();
    }
  }

  public selectItems(): void {
    const isArray = Array.isArray(this.selectedData);
    if (isArray) {
      if (!this.selectedData || this.selectedData.length === 0) {
        this.actualTableData.content
          .filter((x) => x.selected)
          .forEach((c) => {
            c.selected = false;
          });
      } else {
        this.selectedData.forEach((c: any) => {
          c.selected = false;
          this.actualTableData.content.forEach((x) => {
            if (!c.selectDisabled && !x.selectDisabled) {
              const t = this._deepEqual(c, x);
              if (t && !x.selected) {
                x.selected = true;
              } else if (!x.selected && !t) {
                x.selected = false;
              }
            }
          });
        });
      }
      this.checkIfAllAreSelected();
    } else {
      this.actualTableData.content.forEach((x) => {
        if (!x.selectDisabled) {
          const eq = this._deepEqual(this.selectedData, x);
          if (eq) {
            x.selected = true;
          } else {
            x.selected = false;
          }
        }
      });
    }
  }

  public getPropByString(obj: any, propString: string): any {
    if (!propString) { return obj; }

    let prop;
    let index = 0;
    const props = propString.split('.');

    for (let i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];
      index = i;
      const candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[index]];
  }

  private _deepEqual(x: any, y: any): boolean {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;
    const isObj = x && y && tx === 'object' && tx === ty;

    let result: boolean;

    if (isObj) {
      result = ok(x).every((key) => this._deepEqual(x[key], y[key]));
    } else {
      result = x === y;
    }

    return result;
  }

  // tslint:disable-next-line:no-shadowed-variable
    public sortTable(state: TableSortState, columnName: string): void {
    switch (state) {
      case 'asc':
        this.sortAsc(columnName);
        break;
      case 'dsc':
        this.sortDsc(columnName);
        break;
      case 'normal':
        this.sortNormal();
        break;
      default:
        this.sortNormal();
        break;
    }
    if (columnSortable.length > 1) {
      const columnSort = this.tableColumns
        .toArray()
        .find((x) => x.columnProp === columnSortable[0]);
      if (columnSort) {
        columnSort.sortingState = 'normal';
      }
    }
  }

  public sortAsc(column: string): void {
    const content = this.actualTableData.content;
    content.sort((a, b) => {
      let firstValue = this.getPropByString(a, column);
      if (!firstValue) {
        firstValue = '';
      }
      let lastValue = this.getPropByString(b, column);
      if (!lastValue) {
        lastValue = '';
      }

      const sorted = this.compare(firstValue, lastValue, true);

      return sorted;
    });
  }

  public sortDsc(column: string): void {
    const content = this.actualTableData.content;
    content.sort((a, b) => {
      let firstValue = this.getPropByString(a, column);
      if (!firstValue) {
        firstValue = '';
      }
      let lastValue = this.getPropByString(b, column);
      if (!lastValue) {
        lastValue = '';
      }

      const sorted = this.compare(firstValue, lastValue, false);

      return sorted;
    });
  }

  public sortNormal(): void {
    const content = this.actualTableData.content;
    content.sort((a, b) => {
      const sorted = this.compare(a.originalIdx, b.originalIdx, true);

      return sorted;
    });
  }

  private compare(a: any, b: any, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public colSpanSet(): number {
    const columns = this.tableColumns;
    if (columns !== undefined) {
      return columns.toArray().length;
    }
    return 1;
  }

  public selectInTable(data: any): void {
    if (this.pTableSelectMode === 'single') {
      const isSelected = this.actualTableData.content.findIndex(
        (c) => c.selected
      );
      data.selected = !data.selected;
      this.selectedData = data;
      if (isSelected > -1) {
        this.actualTableData.content[isSelected].selected = false;
      }
      if (data.selected) {
        this.selectedDataChange.emit(data);
      } else {
        this.selectedDataChange.emit(undefined);
      }
    } else {
      data.selected = !data.selected;
      const selectedItems = this.actualTableData.content.filter(
        (c) => c.selected
      );
      const arrayCopy = selectedItems.map((v) => Object.assign({}, v));

      arrayCopy.forEach((x) => {
        delete x.selected;
        delete x.originalIdx;
      });
      this.selectedData = arrayCopy;
      this.checkIfAllAreSelected();
      this.selectedDataChange.emit(this.selectedData);
    }
  }

  public checkIfAllAreSelected(): void {
    this.areAllSelected = this.actualTableData.content
      .filter((x) => !x.selectDisabled)
      .every((t) => t.selected);
  }

  public checkIfSomeAreSelected(): boolean {
    return (
      this.actualTableData.content.filter(
        (x) => x.selected && !x.selectDisabled
      ).length > 0 && !this.areAllSelected
    );
  }

  public selectAll(event: boolean): void {
    this.areAllSelected = event;
    this.actualTableData.content.forEach((x) => {
      if (!x.selectDisabled) {
        x.selected = event;
      }
    });
    const selectedItems = this.actualTableData.content.filter(
      (c) => c.selected
    );
    const arrayCopy = selectedItems.map((v) => Object.assign({}, v));

    arrayCopy.forEach((x) => {
      delete x.selected;
      delete x.originalIdx;
    });
    this.selectedData = arrayCopy;
    this.selectedDataChange.emit(this.selectedData);
  }

  public ngOnDestroy(): void {
    columnSortable = [];
  }

  @HostBinding('class.table-expanded')
  public get isExpanded(): boolean {
    return this.pTableExpands;
  }

  @HostBinding('class.dContents')
  public get defaultClass(): boolean {
    return !this.pTableExpands;
  }
}

@Directive({
  selector:
    '[appTableCellTemplate], [pTableCellTemplate], [mcTableCellTemplate]',
})
export class TableCellTemplateDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
@Directive({
  selector:
    '[appTableHeaderTemplate], [pTableHeaderTemplate], [mcTableHeaderTemplate]',
})
export class TableHeaderTemplateDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-table-column, p-table-column',
  template: `
    <th
      (click)="columnSort ? sort() : ''"
      [class.p-column-sortable]="columnSort"
      [style]="columnWidthValue"
    >
      <div style="display: flex; align-items: center;">
        <ng-container
          *ngIf="columnHeaderTemplate"
          [ngTemplateOutlet]="columnHeaderTemplate"
        ></ng-container>
        {{ columnHeaderTemplate ? '' : columnName }}
        <ng-container *ngIf="columnSort">
          <div class="spacer"></div>
          <i style="opacity: 0.4" *ngIf="sortingState === 'normal'" class="bi bi-arrow-down-up"></i>
          <i style="opacity: 0.8" *ngIf="sortingState != 'normal'" class="bi bi-arrow-{{sortingState === 'asc'? 'up':'down'}}"></i>
        </ng-container>
      </div>
    </th>
  `,
})
export class TableColumnComponent implements AfterContentInit {
  @Input() columnName: string;
  @Input() columnProp: string;
  @Input() columnSort: boolean;
  @Input() emitSortEvent: boolean;
  @Input() columnWidth?: number;
  @Input() columnWidthPercentage?: number;
  @Input() customSortFunction: any;
  @Output() sortChanged = new EventEmitter<any>();

  sortingState: TableSortState = 'normal';

  columnCellTemplate: TemplateRef<any>;
  columnHeaderTemplate: TemplateRef<any>;

  @ContentChild(TableCellTemplateDirective)
  tableCell!: TableCellTemplateDirective;
  @ContentChild(TableHeaderTemplateDirective)
  tableHeader!: TableHeaderTemplateDirective;

  public get columnWidthValue(): string {
    if (this.columnWidth) {
      return 'width: ' + this.columnWidth + 'px;';
    }
    if (this.columnWidthPercentage) {
      return 'width: ' + this.columnWidthPercentage + '%;';
    }
    return '';
  }

  constructor(
    public parentComponent: TableComponent,
    public elementRef: ElementRef
  ) {}

  ngAfterContentInit(): void {
    if (this.tableCell) {
      this.columnCellTemplate = this.tableCell.templateRef;
    }
    if (this.tableHeader) {
      this.columnHeaderTemplate = this.tableHeader.templateRef;
    }
  }

  sort(): void {
    switch (this.sortingState) {
      case 'normal':
        this.sortingState = 'asc';
        columnSortable.push(this.columnProp);
        break;
      case 'asc':
        this.sortingState = 'dsc';
        break;
      case 'dsc':
        this.sortingState = 'normal';
        columnSortable.splice(0, 1);
        break;
    }
    if (this.emitSortEvent) {
      this.sortChanged.emit({
        sortState: this.sortingState,
        sortColumn: this.columnProp,
      });
    } else {
      this.parentComponent.sortTable(this.sortingState, this.columnProp);
    }
    this.checkClasses();
  }

  checkClasses(): void {
    if (columnSortable.length > 1) {
      columnSortable.splice(0, 1);
    }
  }

  @HostBinding('class.dContents')
  get DefaultClass(): boolean {
    return true;
  }
}

@Component({
  selector: 'app-table-expanded-row, p-table-expanded-row',
  template: ``,
})
export class TableExpandedRowComponent {
  @Input()
  public expandedRowTemplate: TemplateRef<any>;

  @HostBinding('class.dContents')
  get DefaultClasses(): boolean {
    return true;
  }
}

@Directive({
  selector:
    '[appTableExpandedTrigger], [pTableExpandRowTrigger], [mcTableExpandRowTrigger]',
})
export class TableExpandedTriggerDirective {
  @Input()
  public triggerFor: any;

  @HostListener('click', ['$event'])
  toggleExpandedRow(): void {
    this.triggerFor.expanded = !this.triggerFor.expanded;
  }
}

export class TableData {
  header: TableDataHeader[] = [];
  content: any[] = [];
}

export class TableDataHeader {
  name: string;
  cellTemplate: TemplateRef<any>;
  prop: string;
}

export class SortEvent {
  sortState: string;
  sortColumn: string;
}

type TableSortState = 'normal' | 'asc' | 'dsc';
type TableSelectMode = 'single' | 'multiple';
