import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { RegisterAccountModel } from "../../../services/authentication/models/register-account-model";
import { Router } from "@angular/router";
import { States } from '../../../services/static/models/states';
import { StaticService } from 'src/app/services/static/static.service';
import { EmailVerificationModel } from 'src/app/services/authentication/models/email-verification-model';
import { ValidateCustomCpf, ValidateOnlyText } from '../../shared/validators/type-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import {Ga4Service} from '../../../services/ga4/ga4.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm: UntypedFormGroup;
  listStates: States[];
  termsPdfLink: string = environment.urlTermsPdf;
  registerSuccessfully: boolean = false;
  mobileScreen: boolean;
  screenWidth: number;
  token: string = '';
  registrationId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private router: Router,
    private staticService: StaticService,
    private spinner: NgxSpinnerService,
    private ga4Service: Ga4Service,
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.setRegistrationId();
    this.initializeForm();
    this.getFormStorage();
    this.getListStatesOfBrazil();
  }

  initializeForm() {
    this.registrationForm = this.fb.group({
      name: [null, [Validators.required, ValidateOnlyText]],
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      cpf: [null, [ValidateCustomCpf, Validators.required]],
      crmvNumber: [null, [Validators.required, Validators.minLength(4)]],
      state: [null, Validators.required],
      acceptedTerms: [false, Validators.requiredTrue]
    })
  }

  setFormStorage(field: string) {
    const email = this.registrationForm.get('email')?.value?.toLowerCase();
    this.registrationForm.controls['email'].setValue(email);
    localStorage.setItem('registrationForm', JSON.stringify(this.registrationForm.value));

    this.emitFieldChange(field);
  }

  emitFieldChange(field: string): void {
    const event = `${field}_preenchido`;
    this.ga4Service.emit(event, this.registrationId);
  }

  getFormStorage() {
    this.registrationForm.patchValue(JSON.parse(localStorage.getItem('registrationForm')));
  }

  onSubmit() {
    if (this.registrationForm.controls['state'].value == 'null') {
      this.registrationForm.controls['state'].setValue(null);
    }

    if (this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();
      return;
    }
    this.registerAccount();
  }

  registerAccount() {
    this.spinner.show();
    let register = new RegisterAccountModel();
    register.nome = this.registrationForm.get('name')?.value;
    register.email = this.registrationForm.get('email')?.value;
    register.numeroCrmv = this.pad(this.registrationForm.get('crmvNumber')?.value, 5, "0");
    register.estadoCrmv = this.registrationForm.get('state')?.value;
    register.aceiteTermos = this.registrationForm.get('acceptedTerms')?.value;
    register.cpf = this.registrationForm.get('cpf')?.value;

    this.authenticationService.registerAccount(register).subscribe(
      () => {
        this.toastrService.success('Cadastro realizado com sucesso!');
        this.registerSuccessfully = true;
        this.ga4Service.emit('cadastro_iniciado');
        localStorage.removeItem('registrationForm');
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  getListStatesOfBrazil() {
    this.staticService.getAllStatesOfBrazil().subscribe((res) => {
      this.listStates = res;
    })
  }


  resendEmail() {
    const validateRequest: EmailVerificationModel =
    {
      verificationToken: this.token,
      email: this.registrationForm.get('email')?.value
    };

    this.authenticationService.verificationEmail(validateRequest).subscribe((res) => {
      this.toastrService.success('E-mail enviado com sucesso. Verifique seu spam.');
      this.registerSuccessfully = true;
      localStorage.removeItem('registrationForm');

    },
      error => {
        this.toastrService.error("Token invalido.", "Erro");
      })
  }

  pad(num: string, size: number, char: string): string {
    let s = num + "";
    while (s.length < size) s = char + s;
    return s;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number) {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576 ? this.mobileScreen = false : this.mobileScreen = true;
  }

  setRegistrationId(): void {
    this.registrationId = localStorage.getItem('registrationId');
    if(!this.registrationId){
      this.registrationId = uuidv4();
      localStorage.setItem('registrationId', this.registrationId);
    }
  }

  onBack(): void {
    this.ga4Service.emit('voltar_acionado', this.registrationId)
  }
}
