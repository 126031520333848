import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { TutorModel } from 'src/app/services/tutor/models/tutor-model';
import { TutorService } from 'src/app/services/tutor/tutor.service';
import {
  ValidateCustomDdd,
  ValidateCustomEmail,
} from '../../validators/type-validators';

@Component({
  selector: 'app-tutor-modal',
  templateUrl: './tutor-modal.component.html',
  styleUrls: ['./tutor-modal.component.scss'],
})
export class TutorModalComponent implements OnInit {
  @ViewChild('formDiv') formDiv: ElementRef;

  tutorForm: UntypedFormGroup;
  tutor: TutorModel;
  unsavedTutor: TutorModel;
  dataCollapsed: boolean = false;
  addressCollapsed: boolean = true;
  changedFields = false;
  unsavedData = new Subject<TutorModel>();
  savedTutor = new Subject<TutorModel>();
  phoneWidth = 576;

  whatsappField = false;
  emailField = false;

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private tutorService: TutorService,
    private toastrService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.inicializeForm();
    this.listenHasUnsavedData();
  }

  onToggleChangeWhatsapp() {
    if (!this.whatsappField) {
      this.tutorForm.get('telefoneMovel').setValue('');
      this.whatsappField = true;
      return;
    }

    this.whatsappField = false;
  }

  onToggleChangeEmail() {
    if (!this.emailField) {
      this.tutorForm.get('email').setValue('');
      this.emailField = true;
      return;
    }

    this.emailField = false;
  }

  listenHasUnsavedData(): void {
    this.bsModalRef.onHidden.subscribe(() => {
      if (this.changedFields && window.innerWidth > this.phoneWidth) {
        this.unsavedData.next(this.tutorForm.getRawValue());
      }
    });

    if (this.unsavedTutor) {
      this.changedFields = true;
    }
  }

  inicializeForm(): void {
    this.tutorForm = this.fb.group({
      id: [null],
      nome: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(80),
        ],
      ],
      email: [null, ValidateCustomEmail],
      telefoneMovel: [null, ValidateCustomDdd],
    });

    if (this.tutor && !this.unsavedTutor) {
      this.tutorForm.patchValue(this.tutor);
      this.tutorForm.controls['telefoneMovel'].setValue(
        `${this.tutor.dddTelefoneMovel ?? ''}${this.tutor.telefoneMovel ?? ''}`
      );
    }

    if (this.unsavedTutor) {
      this.tutorForm.patchValue(this.unsavedTutor);
    }
  }

  onSubmit(): void {
    if (this.tutorForm.invalid) {
      this.tutorForm.markAllAsTouched();
      this.toastrService.error(
        window.innerWidth > this.phoneWidth
          ? 'Para continuar é necessário preencher os campos obrigatórios'
          : 'Para continuar preencha os campos obrigatórios'
      );
      return;
    }

    const tutor = this.tutorForm.getRawValue() as TutorModel;
    tutor.dddTelefoneMovel = tutor.telefoneMovel?.substring(0, 2);
    tutor.telefoneMovel = tutor.telefoneMovel?.substring(2);

    if (this.tutor) {
      this.editTutor(tutor);
    } else {
      this.createTutor(tutor);
    }
  }

  createTutor(tutor: TutorModel): void {
    delete tutor.id;
    this.tutorService.createTutor(tutor).subscribe((res) => {
      this.savedTutor.next(res.item);
      this.toastrService.success('Tutor cadastrado com sucesso.');
      this.changedFields = false;
      this.closeModal();
    });
  }

  editTutor(tutor: TutorModel): void {
    this.tutorService.editTutor(tutor, tutor.id).subscribe((res) => {
      this.savedTutor.next(res.item);
      this.toastrService.success('Tutor salvo com sucesso.');
      this.changedFields = false;
      this.closeModal();
    });
  }

  showFooterShadow(): boolean {
    if (this.formDiv) {
      return (
        this.formDiv.nativeElement.scrollHeight >
        this.formDiv.nativeElement.clientHeight
      );
    } else {
      return false;
    }
  }

  closeModal(): void {
    if (window.innerWidth > this.phoneWidth) {
      this.bsModalRef.hide();
      return;
    }

    if (this.changedFields) {
      this.unsavedData.next(this.tutorForm.getRawValue());
    } else {
      this.bsModalRef.hide();
    }
  }
}
