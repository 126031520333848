
<app-background
  [urlNavigation]="urlNavigation">
    <form
    id="id-login-form"
    class="form"
    [formGroup]="loginForm"
    >
      <h1 id="id-login-title" class="form-title"> Faça seu login</h1>
    <div class="d-flex flex-column">
      <div *ngIf="incorrectData" class="error-card">
        <i class="bi bi-info-circle"></i>
        <p class="error-msg" >Usuário ou senha inválidos.</p>
      </div>
      <div>
        <field-validate fieldName="E-mail" [externalError]="incorrectData" [showSuccessBackground]="!incorrectData">
          <input
            #email
            type="text"
            id="id-login-input-email"
            formControlName="email"
            placeholder="Informe seu e-mail"
          />
        </field-validate>
      </div>
      <div class="position-relative">
        <field-validate fieldName="Senha" maxlenght="13" [externalError]="incorrectData" [showSuccessBackground]="!incorrectData" [showPaddingBottom]="false">
          <input
            #password
            [type]="hide? 'password' : 'text'"
            id="id-login-input-password"
            formControlName="password"
            placeholder="Digite sua senha"
            maxlength="13"
          />
          <button id="id-login-btn-password" p-suffix type="button" (click)="hide = !hide" class="button-password" >
            <i id="id-login-btn-password-icon" [ngClass]="hide ? 'bi bi-eye icon-password':'bi bi-eye-slash icon-password'"></i>
          </button>
        </field-validate>
      </div>
      <div id="id-login-reset-password-container" class="text-right">
        <a id="id-login-reset-password" class="reset-login" routerLink="/recuperar-senha">Esqueceu sua senha ?</a>
      </div>
    </div>
    <button id="id-login-btn-submit" type="submit"  class="btn-submit" (click)="submit()">Entrar</button>
      <div class="text-center">
          <span id="id-login-without-credentials" class="without-credentials">
           Não possui uma conta?
           <a routerLink="/cadastro" class="without-credentials__link">Cadastre-se agora </a>
        </span>
      </div>
    </form>
</app-background>

