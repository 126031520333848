import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormControlName, NgModel } from '@angular/forms';

@Component({
  selector: 'field-validate',
  templateUrl: './field-validate.component.html',
  styleUrls: ['./field-validate.component.scss'],
})
export class FieldValidateComponent implements OnInit, AfterContentInit {
  public messageError: string;

  @Input() showSuccessBackground = true;

  @Input() bgColor = 'transparent';

  @Input() fieldName: string;

  @Input() maxLength: string;

  @Input() minLength: string;

  @Input() id: string;

  @Input() validate = true;

  @Input() showOverflow = false;

  @Input() showPaddingBottom = true;

  @Input() customErrorMessage: string;

  @Input() externalError = false;

  @Input() showRequiredAsteristic = true;

  @Input() showField?: boolean;

  public field: any;

  //#region .: Public properties :.
  public message = '';
  //#endregion

  //#region  .: Private properties :.
  private Errors: any = {};
  //#endregion

  inputRequired = false;

  //#region .: View Child properties :.
  @ContentChild(NgModel, { static: false })
  model: NgModel;

  @ContentChild(FormControlName, { static: false })
  control: FormControlName;

  //#endregion

  private required = 'required';
  private maxlengthValid = 'maxlength';
  private minlengthValid = 'minlength';
  private minValid = 'min';
  private maxValid = 'max';
  private invalidDate = 'invalidDate';
  private email = 'email';
  private validEmail = 'hasValidEmail';
  private validCep = 'hasValidCep';
  private cpf = 'validCPF';
  private cnpj = 'validCNPJ';
  private validLoadType = 'validLoadType';
  private validTituloEleitor = 'validTituloEleitor';
  private validPis = 'validPIS';
  private validPlate = 'validPlate';
  private invalidCrmv = 'invalidCrmv';
  private invalidMask = 'mask';
  private invalidDdd = 'hasValidDdd';
  private onlyText = 'onlyText';
  private whiteSpace = 'whiteSpace';

  constructor(private el: ElementRef) { }

  public seedMessages() {
    this.Errors[this.required] = (obj: FieldValidateComponent) =>
      `Preenchimento obrigatório`;
    this.Errors[this.maxlengthValid] = (obj: FieldValidateComponent) =>
      `Excedeu o máximo de caracteres`;
    this.Errors[this.minlengthValid] = (obj: FieldValidateComponent) =>
      `Não foi atingido o minimo de caracteres`;
    this.Errors[this.invalidDate] = (obj: FieldValidateComponent) =>
      `O campo ${obj.fieldName} não é uma data válida`;
    this.Errors[this.email] = (obj: FieldValidateComponent) =>
      `E-mail inválido`;
    this.Errors[this.validEmail] = (obj: FieldValidateComponent) =>
      `E-mail inválido`;
    this.Errors[this.validCep] = (obj: FieldValidateComponent) =>
      `CEP inválido`;
    this.Errors[this.cpf] = (obj: FieldValidateComponent) => `CPF inválido`;
    this.Errors[this.cnpj] = (obj: FieldValidateComponent) => `CNPJ inválido`;
    this.Errors[this.validLoadType] = (obj: FieldValidateComponent) =>
      `O tipo de carregamento não é paletizado`;
    this.Errors[this.validTituloEleitor] = (obj: FieldValidateComponent) =>
      `Título de Eleitor inválido`;
    this.Errors[this.validPis] = (obj: FieldValidateComponent) =>
      `PIS/PASEP inválido`;
    this.Errors[this.validPlate] = (obj: FieldValidateComponent) =>
      `Placa inválida`;
    this.Errors[this.minValid] = (obj: FieldValidateComponent) =>
      `Valor mínimo do campo não atingido`;
    this.Errors[this.maxValid] = (obj: FieldValidateComponent) =>
      `Valor máximo do campo atingido`;
    this.Errors[this.invalidCrmv] = (obj: FieldValidateComponent) =>
      `CRMV inválido`;
    this.Errors[this.invalidMask] = (obj: FieldValidateComponent) =>
      `Campo inválido`;
    this.Errors[this.invalidDdd] = (obj: FieldValidateComponent) =>
      `DDD inválido`;
    this.Errors[this.onlyText] = (obj: FieldValidateComponent) =>
      `Não é permitido números`;
    this.Errors[this.whiteSpace] = (obj: FieldValidateComponent) =>
      `Não é permitido espaço em branco`;
  }

  //#region  .: Input properties :.

  //#endregion

  //#region .: Methods :.
  public ngOnInit(): void {
    if (this.validate) {
      this.seedMessages();
    }
  }

  public ngAfterContentInit(): void {
    this.field = this.model || this.control;
    if (this.field === undefined && this.validate) {
      throw new Error(
        'Esse componente precisa ser usado com uma diretiva NgModel ou FormControlName'
      );
    }
  }

  public hasError(): boolean {
    if (!this.validate) {
      return false;
    }

    if (this.field?.errors) {
      const error = Object.getOwnPropertyNames(this.field.errors);
      error.forEach((element, index) => {
        if (this.Errors[element]) {
          if (index > 0) {
            this.message += ';';
          }
          this.message += this.Errors[element](this);
        }
      });
      this.messageError = this.message;
      this.message = '';
    }
    return this.field?.invalid && (this.field?.dirty || this.field?.touched);
  }

  public isSuccess(): boolean {
    return this.field?.valid && (this.field?.dirty || this.field?.touched);
  }

  hasValue(): boolean {
    let input = this.el.nativeElement.querySelector(
      'input'
    ) as HTMLInputElement;
    if (!input) {
      input = this.el.nativeElement.querySelector('select');
      if (!input) {
        input = this.el.nativeElement.querySelector('textarea');
      }
    }

    if (input?.closest('.p-select-search') === null) {
      if (
        input?.value.length > 0 &&
        input?.value !== 'null' &&
        input?.value !== 'undefined'
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  //#endregion

  @HostListener('click', ['$event']) focused(event: any): void {
    let input = this.el.nativeElement.querySelector(
      'input'
    ) as HTMLInputElement;
    if (!input) {
      input = this.el.nativeElement.querySelector('select');
      if (!input) {
        input = this.el.nativeElement.querySelector('textarea');
      }
    }
    let tgt = event.target.closest('.suffix') as HTMLElement;
    if (!tgt) {
      tgt = event.target.closest('.prefix') as HTMLElement;
    }
    if (!tgt) {
      input.focus();
      const wrap = this.el.nativeElement.firstChild as HTMLDivElement;
      if (input === document.activeElement) {
        wrap.classList.add('focused');
      }
      input.addEventListener('blur', () => {
        wrap.classList.remove('focused');
      });
    }
  }

  @HostBinding('class.has-value')
  get hasVal() {
    return this.hasValue();
  }

  @HostBinding('class.input-error')
  get error() {
    return this.hasError() || this.externalError;
  }

  @HostBinding('class.input-success')
  get success() {
    return this.isSuccess() && this.showSuccessBackground;
  }

  @HostBinding('class.disabled')
  get getDisabled() {
    let input = this.el.nativeElement.querySelector(
      'input'
    ) as HTMLInputElement;
    if (!input) {
      input = this.el.nativeElement.querySelector('select');
      if (!input) {
        input = this.el.nativeElement.querySelector('textarea');
      }
    }
    if (input?.closest('.p-select-search') === null) {
      if (input?.disabled) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  @HostBinding('class.required')
  get getRequired() {
    if (this.control) {
      const abstr = this.control.control?.validator;
      if (abstr) {
        if (abstr({} as AbstractControl)?.required) {
          this.inputRequired = true;
          return true;
        } else {
          this.inputRequired = false;
          return false;
        }
      }
    }
    if (this.model) {
      const abstr = this.model.control.validator;
      if (abstr) {
        if (abstr({} as AbstractControl)?.required) {
          this.inputRequired = true;
          return true;
        } else {
          this.inputRequired = false;
          return false;
        }
      }
    }
    return false;
  }

  @HostBinding('class.show-overflow')
  get ShowOverflow(): boolean {
    return this.showOverflow;
  }

  @HostBinding('class.show-padding-bottom')
  get ShowPaddingBottom(): boolean {
    return this.showPaddingBottom;
  }

  @HostBinding('class.readonly')
  get inputReadonly() {
    let input = this.el.nativeElement.querySelector(
      'input'
    ) as HTMLInputElement;
    if (!input) {
      input = this.el.nativeElement.querySelector('select');
      if (!input) {
        input = this.el.nativeElement.querySelector('textarea');
      }
    }
    if (
      input &&
      !input.parentElement.classList.contains('p-select-parent-wrapper') &&
      input.closest('.p-select-search') === null &&
      !input.classList.contains('picker-trigger') &&
      !input.classList.contains('calendar-desktop-trigger') &&
      !input.classList.contains('p-select-value')
    ) {
      if (input.readOnly) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
