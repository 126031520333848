import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  pathImg = '../../../../../assets/images/logo-premiervet-r.png';
  @Input() urlNavigation: string;
  @Input() hideMobileBackButton = false;
  mobileScreen: boolean;
  screenWidth: number;
  @Output() backEmitter = new EventEmitter<void>();

  constructor() {
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  getNavigate(): void {
    window.location.href = this.urlNavigation;
    this.backEmitter.emit();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 640 ? this.mobileScreen = false : this.mobileScreen = true;
  }
}
