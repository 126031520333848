import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PwaService} from './services/pwa/pwa.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{

  title = 'af-notification';

  constructor(private pwaService: PwaService, private notificationService: NotificationService){}

  ngOnInit(): void {
    this.pwaService.verifyUpdate();
  }

  ngAfterViewInit(): void {
    setInterval(() => {
      this.pwaService.verifyUpdate();
    }, 900000);
  }

}
