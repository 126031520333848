import {ErrorHandler, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ErrorHandlerService} from './services/error-handling.service';
import {LoggingService} from './services/logging.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    LoggingService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ]
})
export class ApplicationInsightsModule {
  constructor(private logService: LoggingService) {}
}
