@if (showPhysicalActivities) {
<div class="container-field">
  <field-validate [fieldName]="label" [showSuccessBackground]="false">
    <select
      data-test-id="desktop-select"
      [(ngModel)]="selectedActivityId"
      [required]="true"
      (ngModelChange)="handleOptionSelected()"
    >
      <option  [ngValue]="null">Selecione</option>
      @for (activity of physicalActivities; track activity) {
      <option [ngValue]="activity.id">
        <span
          [innerHTML]="
            activity.name +
            (activity.description ? ' - ' + activity.description : '')
          "
        ></span>
      </option>
      }
    </select>
  </field-validate>
</div>
}
