import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-paginator, p-paginator',
  styleUrls: ['paginator-table.component.scss'],
  template: `
    <div class="pagination-footer">
      <span>Exibindo {{totalItemsPage}} de {{totalRows}} resultados</span>
      <div *ngIf="showPaginator" class="paginator">
        <button [disabled]="currentPage == +numbers[0]" class="button-paginator" (click)="previousPage()">
          <i class="bi bi-arrow-left" style="padding-right: 0.3rem"></i>
          Voltar
        </button>
        <div class="numbers">
          <button class="button-paginator" [class.selected]="currentPage == +number" *ngFor="let number of numbers" (click) = "selectPage(number)">{{number}}</button>
        </div>
        <button [disabled]="currentPage == +numbers[numbers.length - 1]" class="button-paginator" (click)="nextPage()">
          Próximo
          <i class="bi bi-arrow-right" style="padding-left: 0.3rem"></i>
        </button>
      </div>
    </div>
  `,
})
export class PaginatorComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  totalRows = 1;
  @Input()
  maxRowsPerPage = 1;
  @Input()
  currentPage = 1;
  @Output()
  currentPageChange = new EventEmitter<number>();
  totalPagesCount = 0;
  totalItemsPage = 0;
  pageNumbers: string[];
  showPaginator = false;
  numbers: string[] = [];

  ngOnInit(): void { }

  calcPages(): void {
    this.totalPagesCount = Math.ceil(this.totalRows / this.maxRowsPerPage);
    this.pageNumbers = Array.from({ length: this.totalPagesCount }).map((_, i) => (i + 1).toString());
    this.createPages();
  }

  createPages(): void {
    this.numbers = ['1'];

    if (this.currentPage < 10) {
      for (let i = 2; i <= Math.min(this.totalPagesCount, 10); i++) {
        this.numbers.push(`${i}`);
      }

      if (this.totalPagesCount > 10) {
        this.numbers = [...this.numbers, '...', `${this.totalPagesCount}`];
      }
    } else if (this.currentPage > this.totalPagesCount - 10) {
      this.numbers.push('...');
      for (let i = this.totalPagesCount - 8; i <= this.totalPagesCount; i++) {
        this.numbers.push(`${i}`);
      }
    } else {
      this.numbers.push('...');

      for (let i = this.currentPage - 4; i <= this.currentPage + 4; i++) {
        this.numbers.push(`${i}`);
      }

      this.numbers = [...this.numbers, '...', `${this.totalPagesCount}`];
    }
  }

  ngAfterViewInit(): void {
    this.showComponentPaginator();
  }

  showComponentPaginator(): void {
    this.showPaginator = this.pageNumbers?.length > 0;
  }

  selectPage(page: string): void {
    if(page === '...') {
      return;
    }
    this.currentPage = +page;
    this.currentPageChange.emit(this.currentPage);
    this.calcTotalRows();
    this.createPages();
  }
  previousPage(): void {
    this.currentPage = this.currentPage <= 0 ? 1 : this.currentPage - 1;
    this.currentPageChange.emit(this.currentPage);
    this.calcTotalRows();
    this.createPages();
  }
  nextPage(): void {
    this.currentPage = this.pageNumbers.length > this.currentPage ? this.currentPage + 1 : this.pageNumbers.length;
    this.currentPageChange.emit(this.currentPage);
    this.calcTotalRows();
    this.createPages();

  }
  calcTotalRows(): void {
    const total = this.currentPage * this.maxRowsPerPage;
    this.totalItemsPage = total > this.totalRows ? this.totalRows : total;
  }

  calcTotalRowsPrevious(): void {
    const total = this.currentPage * this.maxRowsPerPage;
    this.totalItemsPage = total <= 0 ? 1 : total - this.maxRowsPerPage;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage) {
      this.currentPage++;
    } else {
      this.calcPages();
      this.calcTotalRows();
      this.showComponentPaginator();
    }
  }
}
