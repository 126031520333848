export interface Slide {
  img: string;
  title: string;
  description: string;
  secondDescription: string;
}

export const slidesObesityTreatment = [
  {
    img: '../../../../../assets/images/popup/banner-2-mobile.png',
    title: 'Tratamento de Redução de Peso: como aplicar?',
    description: `Após definir a “Finalidade da Orientação” como “Obesidade”, selecione em “Objetivo do Tratamento” a opção <strong>“Redução de Peso - Iniciar Tratamento” </strong> para começar um novo tratamento do pet, ou  <strong>“Redução de Peso - Retorno”</strong>  para realizar o acompanhamento mensal do paciente.`,
    secondDescription: ''

  },
  {
    img: '../../../../../assets/images/popup/banner-3-mobile.png',
    title: 'Como fazer o acompanhamento do pet?',
    description: 'Monitore mensalmente o escore corporal e o peso do seu paciente',
    secondDescription: 'Crie orientações de Retorno a cada atualização de peso para que, se necessário, o tempo de tratamento e a ingestão diária de alimento sejam recalculadas.',

  },
  {
    img: '../../../../../assets/images/popup/banner-4-mobile.png',
    title: 'Como verificar o progresso do tratamento?',
    description: 'Acompanhe o progresso da perda de peso do início ao fim do tratamento informando apenas o peso do pet.',
    secondDescription: 'A PremieRvet® calculará automaticamente para você: o tempo de tratamento, a quantidade diária de alimento e o peso a ser perdido.',

  },
]
