<div
  *ngIf="gradualChange"
  [ngClass]="
    lengthList > 5
      ? 'gradual-change__result__large row'
      : 'gradual-change__result row'
  "
  class="gradual-change__result row"
>
  <div class="box-subtitle">
    <p class="food-previous">Alimento anterior</p>
    <p class="food-new">Novo alimento</p>
  </div>

  <div *ngFor="let item of listProductsOfGradualChange">
    <div
      [ngClass]="
        lengthList > 5
          ? 'data-container-gradual-changes__large col-auto'
          : 'data-container-gradual-changes col-auto'
      "
      class="data-container-gradual-changes col-auto"
    >
      <span [innerHTML]="item.day"></span>
      <img
        [ngClass]="lengthList > 5 ? 'short-img' : 'medium-img'"
        [src]="urlImgGradualChange + item.imageName"
        alt="imagem ilustrativa da troca de produto"
      />
      <p
        [ngClass]="
          lengthList > 5
            ? 'gradual-change-quantity__large'
            : 'gradual-change-quantity'
        "
        [innerHTML]="item.percentagePreviousFood + '%'"
      ></p>
      <div class="w-100 container-percent-new-food">
        <p
          class="gradual-change-quantity-new-food"
          [innerHTML]="item.percentageNewFood + '%'"
        ></p>
      </div>
    </div>
  </div>
</div>
