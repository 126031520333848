import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mobile-datepicker',
  templateUrl: './mobile-datepicker.component.html',
  styleUrls: ['./mobile-datepicker.component.scss']
})
export class MobileDatepickerComponent implements OnInit {
  @Input() selectedDate: Date;
  bsConfig = {
    maxDate: new Date(),
    showWeekNumbers: false,
    selectFromOtherMonth: false
  };

  formattedDate: string;
  showAlert = false;

  @Output() selectedDateEmitter = new EventEmitter<Date>();
  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.formatDate();
  }

  setDate(date: Date): void {
    this.selectedDate = date;
    this.showAlert = false;
    this.formatDate();
  }

  formatDate(): void {
    if (!this.selectedDate) {
      this.formattedDate = '00/00/0000';
    } else {
      this.formattedDate = this.datePipe.transform(this.selectedDate, 'dd/MM/yyyy');
    }
  }

  confirm(): void {
    if (!this.selectedDate) {
      this.showAlert = true;
      return;
    }
    this.selectedDateEmitter.next(this.selectedDate);
  }
}
