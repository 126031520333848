import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidateCustomEmail } from 'src/app/modules/shared/validators/type-validators';
import { PrescriptionService } from 'src/app/services/prescription/prescription.service';

@Component({
  selector: 'app-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.scss']
})
export class ModalSendEmailComponent implements OnInit {

  emails: string[] = [];
  emailForm: UntypedFormGroup;
  showErrorMessage = false;
  showMaxEmailsError = false;
  maxEmails = 10;

  @Output() closeChange = new EventEmitter();
  @ViewChild('inputEmail') inputEmailRef: ElementRef;
  @Input() orientationId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: [null, ValidateCustomEmail]
    })
  }

  onSubmit(): void {
    if (this.emailForm.value.email?.length) {
      this.addEmail(this.emailForm.value.email);
    } else {
      this.showErrorMessage = false;
    }
    if (this.emails.length) {
      this.sendEmail();
    } else {
      this.showErrorMessage = true;
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    const keys = ['Comma', 'Space', 'Enter', 'NumpadEnter'];
    this.validateMaxEmails();
    const IsCommaOrSpace = this.checkCommaAndSpaceForMobile(this.emailForm.value.email);
    const inputValue = this.emailForm.value.email?.replace(',', '')?.replace(' ', '');
    if (keys.includes(event.code) || keys.includes(event.key) || IsCommaOrSpace) {
      this.emailForm.get('email').setValue(inputValue);
      this.addEmail(inputValue);
    }
  }

  checkCommaAndSpaceForMobile(text: string): boolean {
    return text.substring(text.length - 1) === ',' || text.substring(text.length - 1) === ' ';
  }

  validateMaxEmails() {
    if (this.emails.length >= this.maxEmails && this.emailForm.value.email) {
      this.showMaxEmailsError = true;
    } else {
      this.showMaxEmailsError = false;
    }
  }

  addEmail(email: string) {
    if (this.emailForm.valid && email?.length) {
      if (!this.showMaxEmailsError) {
        this.emails.push(email)
        this.emailForm.reset();
        this.showErrorMessage = false;
      }
    } else {
      this.showErrorMessage = true;
    }
  }

  removeEmail(email?: string): void {
    if (email) {
      this.emails.splice(this.emails.indexOf(email), 1);
    } else {
      this.emails.splice(-1);
    }
    this.validateMaxEmails();
  }

  sendEmail(): void {
    const request = {
      enderecos: this.emails,
      idPrescricao: this.orientationId
    }
    this.prescriptionService.sendMail(request).subscribe(() => {
      this.toastrService.success('E-mail enviado com sucesso!');
      this.closeModal();
    });
  }

  focusInput() {
    this.inputEmailRef.nativeElement.focus();
  }

  closeModal(): void {
    this.closeChange.next();
  }
}