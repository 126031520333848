<div class="container-filter">
  <div *ngFor="let item of checkboxes let i = index ">
    <label [for]="i"> {{item.title}} </label>
    <input
      type="checkbox"
      [id]="i"
      value="{{item.value}}"
      [checked]='item.selected'
      class="input-checkbox outline-primary"
      (change)="item.type == 'specie' ? getSpecies($event): getGenders($event)"/>
  </div>
</div>
<hr>
<div class="footer">
  <button class="btn-close" (click)="onCloseModal()">Fechar</button>
</div>

