<div class="col-12 no-gutters d-flex flex-column box-geral">
  <div class="box_info-tags">
    <p class="titulo-tags">{{ titulo }}</p>

    <p class="descricao-tags">{{ subtitulo }}</p>
  </div>

  <div class="col-12 container-modal">
    <div class="box-tags mb-3">
      <div
        *ngFor="let item of selectedTags"
        [ngClass]="'tag-grupo-' + item.grupoId"
      >
        <div class="d-flex tags-btn">
          <span id="item.id">{{ item.text }}</span>
          <button (click)="removeTag(item)">
            <img src="../../../../../assets/icons/circle-plus.png" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-select p-0 g-0 col-12"
    [ngClass]="
      disabled
        ? 'container-select -disabled p-0 g-0 col-12'
        : 'container-select p-0 g-0 col-12'
    "
  >
    <select
      [(ngModel)]="model"
      type="text"
      (change)="addTag()"
      [disabled]="disabled"
    >
      <option [value]="null">Selecione</option>
      <option [ngValue]="item" *ngFor="let item of conditions">
        {{ item.text }}
      </option>
    </select>
  </div>
</div>
