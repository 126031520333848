import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[nextField]',
})
export class NextFieldDirective {
  @Input() nextField: HTMLInputElement;
  constructor() {}

  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.nextField.focus();
    }
  }


}
