import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from '../../shared/validators/custom-validators';
import {
  noWhiteSpaceValidator,
  ValidateHasCapitalCase,
  ValidateHasNumber,
  ValidatehasSmallCase,
  ValidatehasSpecialCharacters,
} from '../../shared/validators/type-validators';
import { Location } from '@angular/common';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { EmailVerificationModel } from "../../../services/authentication/models/email-verification-model";
import { RegisterPasswordModel } from "../../../services/authentication/models/register-password-model";
import { PasswordVerificationModel } from 'src/app/services/authentication/models/password-verification-model';
import {Ga4Service} from '../../../services/ga4/ga4.service';

@Component({
  selector: 'app-registration-password',
  templateUrl: './registration-password.component.html',
  styleUrls: ['./registration-password.component.scss'],
})
export class RegistrationPasswordComponent implements OnInit {
  hidePassword = true;
  hideConfirmPassword = true;
  newPassForm: UntypedFormGroup;
  email: string;
  token: string = '';
  tokenInvalid = false;
  emailValidated: boolean = false;
  success: boolean = false;
  createNewPassword: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private ga4Service: Ga4Service) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });

    if (this.route.snapshot.routeConfig.path == 'reset-password') {
      this.createNewPassword = false;
      this.location.replaceState("/reset-password");
    } else {
      this.createNewPassword = true;
      this.location.replaceState("/new-password");
    }
  }

  ngOnInit(): void {
    this.createFormNewPass();

    if (this.createNewPassword) {
      this.validateEmailToken();
    } else {
      this.validateChangePasswordToken();
    }
  }

  validateChangePasswordToken() {
    const validateRequest: PasswordVerificationModel =
      {
        hash: this.token,
        email: this.email
      };

    this.authenticationService.validatePassword(validateRequest).subscribe(
      () => {
        this.emailValidated = true;
      },
      (error) => {
        this.tokenInvalid = true;
        this.toastService.error("Token invalido.", "Erro")
      }
    )
  }

  validateEmailToken() {
    const validateRequest: EmailVerificationModel =
      {
        verificationToken: this.token,
        email: null
      };

    this.authenticationService.validateEmail(validateRequest).subscribe((res) => {
        this.emailValidated = true;
      },
      error => {
        this.tokenInvalid = true;
        this.toastService.error("Token invalido.", "Erro")
      })
  }

  createFormNewPass() {
    this.newPassForm = this.fb.group(
      {
        password: [null, Validators.compose([
          Validators.required,
          noWhiteSpaceValidator,
          Validators.minLength(7),
          ValidateHasNumber,
          ValidateHasCapitalCase,
          ValidatehasSmallCase,
          ValidatehasSpecialCharacters,
        ])
        ],
        confirmPassword: [null, Validators.required]
      },
      {
        validator: CustomValidators.passwordMatchValidator
      });
  }

  Space(event: any) {
    if (event.code == "Space") {
      event.preventDefault();
    }
  }

  onSubmit() {
    if (this.newPassForm.invalid) {
      this.toastService.error("A senha deve atender as regras descritas. Senha não permitida");
      this.newPassForm.markAllAsTouched();
      return;
    }

    if (this.createNewPassword) {
      this.registerPassword();
    } else {
      this.changePassword();
    }
  }

  changePassword() {
    const changePasswordRequest = {
      email: this.email,
      password: this.newPassForm.value.password,
      hash: this.token
    }

    this.authenticationService.changePassword(changePasswordRequest).subscribe(
      () => {
        this.success = true;
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 5000);
      }
    )
  }

  registerPassword() {
    const form = this.newPassForm.value;
    const registerPasswordRequest: RegisterPasswordModel = {
      verificationToken: this.token,
      password: form.password
    }

    this.authenticationService.registerPassword(registerPasswordRequest).subscribe(
      (res) => {
        this.success = true;

        setTimeout(() => {
          this.ga4Service.emit('cadastro_finalizado');
          localStorage.removeItem('registrationId');
          this.router.navigate(['login'])

        }, 5000);

      },
      (error) => {
        const errors = Object.values<string[]>(error?.error)[0];
        this.toastService.error(errors[0])
      });
  }

  goToLogin() {
    this.router.navigate(['login'])
  }
}
