import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ClinicItem} from '../../../../services/clinic/models/clinic-model';
import {Cep, ViacepService} from '../../../../services/viacep.service';
import {LookupBaseModel} from '../../../../services/lookup/models/lookup-base-model';
import {LookupService} from '../../../../services/lookup/lookup.service';
import {
  ValidateCustomCep,
  ValidateCustomCnpj,
  ValidateCustomDdd,
  ValidateCustomEmail
} from '../../validators/type-validators';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {ClinicService} from '../../../../services/clinic/clinic.service';
import { StaticService } from 'src/app/services/static/static.service';
import { States } from 'src/app/services/static/models/states';

@Component({
  selector: 'app-establishment-modal',
  templateUrl: './establishment-modal.component.html',
  styleUrls: ['./establishment-modal.component.scss']
})
export class EstablishmentModalComponent implements OnInit {
  establishmentForm: UntypedFormGroup;
  unSavedEstablishment: ClinicItem;
  establishment: ClinicItem;
  changedFields = false;
  selectedState: string;
  cities: LookupBaseModel[] = [];
  listStates: States [];
  savedEstablishment = new Subject<ClinicItem>();
  unsavedData = new Subject<ClinicItem>();
  phoneWidth = 576;
  logoUrl: string;
  constructor(
    private bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private viaCepService: ViacepService,
    private lookupService: LookupService,
    private toastrService: ToastrService,
    private clinicService: ClinicService,
    private staticService: StaticService
  ) { }

  ngOnInit(): void {
    this.inicializeForm();
    this.listenHasUnsavedData();
    this.getListStatesOfBrazil();
  }
  inicializeForm(): void {
    this.establishmentForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required, Validators.maxLength(80), Validators.minLength(3)]],
      classificacao: [null, [Validators.required]],
      email: [null, [Validators.required, ValidateCustomEmail]],
      cnpj: [null, [Validators.required, ValidateCustomCnpj]],
      telefoneMovel: [null, ValidateCustomDdd],
      telefoneFixo: [null, ValidateCustomDdd],
      cep: [null, [ValidateCustomCep, Validators.required]],
      estado: [null, [Validators.required]],
      cidade: [null, [Validators.required]],
      idCidade: [null, [Validators.required]],
      bairro: [null, [Validators.required]],
      logradouro: [null, [Validators.required]],
      numero: [null, [Validators.min(1), Validators.required]],
      complemento: [null, [Validators.maxLength(29)]]
    });

    if (this.establishment && !this.unSavedEstablishment) {
      this.establishmentForm.patchValue(this.establishment);
      this.logoUrl = this.establishment.logoUrl;
      this.establishmentForm.controls.telefoneMovel.setValue(`${this.establishment.dddTelefoneMovel ?? ''}${this.establishment.telefoneMovel ?? ''}`);
      this.establishmentForm.controls.telefoneFixo.setValue(`${this.establishment.dddTelefoneFixo ?? ''}${this.establishment.telefoneFixo ?? ''}`);
      this.getCity(this.establishment.idCidade);
    }

    if (this.unSavedEstablishment) {
      this.establishmentForm.patchValue(this.unSavedEstablishment);
      this.getCity(this.unSavedEstablishment.idCidade);
    }
  }
  listenHasUnsavedData(): void {
    this.bsModalRef.onHidden.subscribe(() => {
      if (this.changedFields && window.innerWidth > this.phoneWidth) {
        this.unsavedData.next(this.establishmentForm.getRawValue());
      }
    });

    if (this.unSavedEstablishment) {
      this.changedFields = true;
    }
  }
  closeModal(): void {
    if (window.innerWidth > this.phoneWidth) {
      this.bsModalRef.hide();
      return;
    }

    if (this.changedFields) {
      this.unsavedData.next(this.establishmentForm.getRawValue());
    }else {
      this.bsModalRef.hide();
    }
  }
  onSubmit(): void {
    const city = this.cities.find(c => c.id == this.establishmentForm.controls.idCidade.value);
    if (city) {
      this.establishmentForm.controls.cidade.setValue(city.text);
    }

    if (this.establishmentForm.invalid) {
      this.establishmentForm.markAllAsTouched();
      this.toastrService.error(window.innerWidth > this.phoneWidth ?
        'Para continuar é necessário preencher os campos obrigatórios' :
        'Para continuar preencha os campos obrigatórios');
      return;
    }

    const establishment = this.establishmentForm.getRawValue() as ClinicItem;
    establishment.dddTelefoneMovel = establishment.telefoneMovel?.substring(0, 2);
    establishment.telefoneMovel = establishment.telefoneMovel?.substring(2);
    establishment.dddTelefoneFixo = establishment.telefoneFixo?.substring(0, 2);
    establishment.telefoneFixo = establishment.telefoneFixo?.substring(2);
    establishment.isActive = true;
    establishment.logoUrl = this.logoUrl;

    if (this.establishment) {
      this.editClinic(establishment);
    }else {
      this.createEstablishment(establishment);
    }
  }
  createEstablishment(establishment: ClinicItem): void {
    delete establishment.id;
    this.clinicService.createClinic(establishment).subscribe(res => {
      const city = this.cities.find(c => c.id === res.item.idCidade);
      res.item.uf = city.uf;
      res.item.nomeCidade = city.text;
      this.savedEstablishment.next(res.item);
      this.toastrService.success('Estabelecimento cadastrado com sucesso.');
      this.changedFields = false;
      this.closeModal();
    });
  }

  editClinic(clinic: ClinicItem): void {
    this.clinicService.editClinic(clinic, clinic.id).subscribe(res => {
      const city = this.cities.find(c => c.id === res.item.idCidade);
      res.item.uf = city.uf;
      res.item.nomeCidade = city.text;
      this.savedEstablishment.next(res.item);
      this.toastrService.success('Estabelecimento atualizado com sucesso.');
      this.changedFields = false;
      this.closeModal();
    });
  }
  searchCep(): void {
    this.changeDetectorRef.detectChanges();
    const cep = this.establishmentForm.controls.cep.value;
    if (cep.length < 8) {
      return;
    }

    this.viaCepService.getCep(cep).subscribe(res => {
      const cep = res as Cep;
      this.establishmentForm.controls.logradouro.setValue(cep.logradouro);
      this.establishmentForm.controls.complemento.setValue(cep.complemento);
      this.establishmentForm.controls.bairro.setValue(cep.bairro);
      this.establishmentForm.controls.estado.setValue(cep.uf ?? null);
      this.establishmentForm.controls.cidade.setValue(cep.localidade);
      this.loadCities(true);
    });
  }
  autoSelectCity(): void {
    const idCity = this.cities.find(c => c.text == this.establishmentForm.controls.cidade.value)?.id;
    this.establishmentForm.controls.idCidade.setValue(idCity);
  }

  loadCities(autoSelectCity = false): void {
    const state = this.establishmentForm.controls.estado.value;
    if (state === this.selectedState && autoSelectCity && this.cities.length) {
      this.autoSelectCity();
      return;
    }

    if (!state) {
      this.cities = [];
      this.establishmentForm.controls.idCidade.setValue(null);
      this.establishmentForm.controls.idCidade.disable();
      return;
    }

    this.lookupService.getCities(state).subscribe(res => {
      this.cities = res.items;
      this.selectedState = state;
      if (autoSelectCity) {
        this.autoSelectCity();
      }
    });
  }
  getCity(cityId: number): void {
    if (cityId) {
      this.lookupService.getCity(cityId).subscribe(res => {
        this.establishmentForm.controls.estado.setValue(res.uf);
        this.establishmentForm.controls.cidade.setValue(res.text);
        this.loadCities();
      });
    }
  }

  getListStatesOfBrazil(): void {
    this.staticService.getAllStatesOfBrazil().subscribe((res) => {
      this.listStates = res;
    });
  }
}
