<div *ngIf="modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS'">

  <div *ngIf="!notificationClosed" class="modal-pwa">
    <!-- Android -->
    <div *ngIf="modalPwaPlatform === 'ANDROID'" (click)="addToHomeScreen()" class="notification-message">
      <img class="icon" src="../assets/icons/icon-pv-pwa-72.png" alt="ícone premiervet">
      <div class="message">Deseja instalar o PremieRvet®? <span>Clique aqui</span></div>
      <button (click)="closeNotification()" class="close-button"><i class="bi bi-x icon__close"></i></button>
    </div>
    <!-- iOS with Safari -->
    <div *ngIf="modalPwaPlatform === 'IOS'" class="notification-message">
      <div class="pvet-logo">
        <img class="icon" src="../assets/icons/icon-pv-pwa-72.png" alt="ícone premiervet">
        <div class="message">
          Para adicionar o atalho do PremieRvet®, clique no "Menu"
          <img
            src="https://res.cloudinary.com/rodrigokamada/image/upload/v1641089482/Blog/angular-pwa/safari_action_button_38x50.png"
            class="ios-menu m-0" />
           selecione a opção "Adicione à tela de início"
          <i class="bi bi-plus-square"></i>
        </div>
      </div>
      <button (click)="closeNotification()" class="close-button"><i class="bi bi-x icon__close"></i></button>
    </div>
  </div>
</div>
