import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TutorService } from '../../../../services/tutor/tutor.service';
import { TutorModel } from '../../../../services/tutor/models/tutor-model';

@Component({
  selector: 'app-register-tutor-number-modal',
  templateUrl: './register-tutor-number-modal.component.html',
  styleUrls: ['./register-tutor-number-modal.component.scss']
})
export class RegisterTutorNumberModalComponent implements OnInit {
  tutorId: number;
  tutor: TutorModel;
  tutorForm: UntypedFormGroup;
  backgroundMobile = '../../../../../assets/images/cat-background-mobile.png';

  celphoneEmitter = new EventEmitter<string>();
  constructor(
    private bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private tutorService: TutorService
  ) { }

  ngOnInit(): void {
    this.tutorForm = this.fb.group({
      celphone: [null, [Validators.required]]
    });

    this.getTutor();
  }

  getTutor(): void {
    this.tutorService.getTutor(this.tutorId).subscribe(tutor => {
      this.tutor = tutor;
    },
    error => setTimeout(() => this.closeModal(), 200));
  }

  updateTutorNumber(): void {
    const celphone = this.tutorForm.value.celphone;
    this.tutor = {
      ...this.tutor,
      dddTelefoneMovel: celphone.slice(0, 2),
      telefoneMovel: celphone.slice(2)
    };

    this.tutorService.editTutor(this.tutor, this.tutorId).subscribe(() => {
      this.celphoneEmitter.emit(celphone);
      this.closeModal();
    });
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
