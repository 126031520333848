import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Score } from './model/score.model';

@Injectable({
  providedIn: 'root',
})
export class ScoreService {
  private apiCalculatorUrl = `${environment.apiCalculator}/calculator/getObesityScores`;

  constructor(private http: HttpClient) {}

  getScores(specie: number, orientationPurposeId: number) {
    return this.http.get<Score[]>(
      `${this.apiCalculatorUrl}/${specie}/${orientationPurposeId}`
    );
  }
}
