<app-background
  urlNavigation="/#/login">
  <div class="success">
    <img src="../../../../assets/images/registration/email-send.svg" alt="E-mail">
    <div>
      <h2 class="success-title">Atualizamos o nosso sistema, será necessário atualizar a sua senha. Verifique seu e-mail:</h2>
      <p class="success-email">{{ email }}</p>
    </div>
  </div>
</app-background>
