import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateBrPipe',
})
export class DateBrPipe implements PipeTransform {
  transform(value: any): any {
    return new Date(value).toLocaleDateString('pt-BR', { timeZone: 'Brazil/East'});
  }
  formatVet(value: any): any{
    const date = new Date(value).toLocaleDateString('pt-BR', { timeZone: 'Brazil/East'});
    const day = date.substring(0, 2);
    const month = date.substring(3, 5);
    const year = date.substring(8, 10);
    return `${day} ${this.getMonth(month)} ${year}`;
  }
  private getMonth(monthSelected: string): string{
   const months = [
      {
        month: '01',
        name: 'Jan'
      },
      {
        month: '02',
        name: 'Fev'
      }
      ,
      {
        month: '03',
        name: 'Mar'
      }
      ,
      {
        month: '04',
        name: 'Abr'
      }
      ,
      {
        month: '05',
        name: 'Mai'
      }
      ,
      {
        month: '06',
        name: 'Jun'
      }
      ,
      {
        month: '07',
        name: 'Jul'
      }
      ,
      {
        month: '08',
        name: 'Ago'
      }
      ,
      {
        month: '09',
        name: 'Set'
      }
      ,
      {
        month: '10',
        name: 'Out'
      }
      ,
      {
        month: '11',
        name: 'Nov'
      },
      {
        month: '12',
        name: 'Dez'
      }
    ];

   return months.find(x => x.month === monthSelected).name;

  }


}
