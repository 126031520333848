<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>

    <div class="modal-actions">
      <button class="button button-close" (click)="closeModal()">
        Cancelar
      </button>
      <button class="button button-primary" (click)="confirm()">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
