import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-principal-popup-obesity-treament-modal',
  standalone: false,
  templateUrl: './principal-popup-obesity-treament-modal.component.html',
  styleUrl: './principal-popup-obesity-treament-modal.component.scss'
})
export class PrincipalPopupObesityTreamentModalComponent implements OnDestroy {
  isChecked: boolean = false;
  showSliders = new EventEmitter<boolean>();
  mobileScreen: boolean;
  screenWidth: number;
  @ViewChild('modalMobile', { static: false }) modalMobile: ElementRef;
  constructor(
    private modalRef: BsModalRef,
    private router: Router,
  ) {
    this.getScreenSize();
  }

  closeModal(): void {

    if (this.modalMobile) {
      const element = this.modalMobile.nativeElement;
      element.classList.add('hidden-modal');
      setTimeout(() => {
        this.modalRef.hide();
    }, 200)
    }
    else {
      return this.modalRef.hide();
    }


  }

  setInfoLocalStorage(event: any ) {
    const isChecked = event.target.checked;
    this.showSliders.next(isChecked);
  }
  getNewOrientation() : void {
    localStorage.setItem('notShowSlider','false');
    this.router.navigate(['orientacao/nova-orientacao']);
    this.closeModal();

  }

  setNotViewMoreMobile() {
    this.showSliders.next(true);
    this.closeModal();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number) {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576 ? this.mobileScreen = false : this.mobileScreen = true;
  }

  ngOnDestroy(): void {
    if (this.modalMobile) {
      const element = this.modalMobile.nativeElement;
      element.classList.remove('hidden-modal');
    }
   }
}
