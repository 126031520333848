import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PrescriptionEmailModelRequest, PrescriptionEmailModelResponse } from './models/prescription-email-model';
import {PrescriptionDetailsResponse} from "./models/prescription-details-model";
import { BaseListRequest } from '../shared/base-list-request';
import { AppListResponse, AppResponse } from '../shared/app.response';
import { PrescriptionItem, PrescriptionModel } from './models/prescription-model';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../shared/loading.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  private apiUrl = `${environment.baseApimUrl}/prescricao`;

  constructor(private http: HttpClient, private loadingService: LoadingService) { }

  getbyId(prescriptionId:string): Observable<PrescriptionDetailsResponse>{
    return this.http.get<PrescriptionDetailsResponse>(`${this.apiUrl}/${prescriptionId}/detail`);
  }
  sendMail(request: PrescriptionEmailModelRequest): Observable<PrescriptionEmailModelResponse> {
    return this.http.post<PrescriptionEmailModelResponse>(`${this.apiUrl}/mail/send`, request);
  }

  sendPdf(prescriptionCode: string, file: File): Observable<string> {
    let formData: any = new FormData();
    formData.append('file', file);
    formData.append('DomainId', prescriptionCode);
    return this.http.post<string>(`${this.apiUrl}/arquivo`, formData);
  }

  listPrescriptions(request: BaseListRequest): Observable<AppListResponse<PrescriptionModel>> {
    const url = `${this.apiUrl}/list`;
    return this.http.post<AppListResponse<PrescriptionModel>>(url, request);
  }

  createPrescription(prescription: PrescriptionItem): Observable<AppResponse<PrescriptionItem>> {
    const headers = this.loadingService.getLoadingHeader();
    const url = `${this.apiUrl}/create`;
    return this.http.post<AppResponse<PrescriptionItem>>(url, prescription, {headers});
  }
}
