import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GradualExchange } from 'src/app/services/calculator/models/calculator-product-result-model';

@Component({
  selector: 'app-gradual-change',
  templateUrl: './gradual-change.component.html',
  styleUrls: ['./gradual-change.component.scss'],
})
export class GradualChangeComponent implements OnChanges {
  @Input() gradualChange: boolean = false;
  @Input() listProductsOfGradualChange: GradualExchange[];

  urlImgGradualChange: string =
    '../../../../../assets/images/gradual-exchange/';
  lengthList: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.lengthList = this.listProductsOfGradualChange.length;
    }
  }
}
