import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Slide, slidesObesityTreatment } from './slides-obesity-treatment.model';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'slides-obesity-treatment',
  standalone: false,
  templateUrl: './slides-obesity-treatment.component.html',
  styleUrl: './slides-obesity-treatment.component.scss',
})
export class SlidesObesityTreatmentComponent {
  @ViewChild('firstSlide') firstSlide: ElementRef;
  @ViewChild('secondSlide') secondSlide: ElementRef;
  @ViewChild('thirdSlide') thirdSlide: ElementRef;
  @ViewChild('slidesMobile', { static: false }) slidesMobile: ElementRef;
  isChecked: boolean = false;
  mobileScreen: boolean;
  screenWidth: number;
  listSlides:Slide[] = slidesObesityTreatment;
 showSliders = new EventEmitter<boolean>();
  currentTemplate: string = 'firstTemplate';
  config: SwiperOptions = {
    pagination: {
      clickable: true,
    },
    navigation: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
  };
  constructor(private modalRef: BsModalRef) {
    this.getScreenSize();
  }

  closeModal(): void {

    if (this.slidesMobile) {
      const element = this.slidesMobile.nativeElement;
      element.classList.add('hidden-modal');
      setTimeout(() => {
        this.modalRef.hide();
    }, 200)
    }
    else {
      return this.modalRef.hide();
    }


  }

  nextSlide(): void {
    switch (this.currentTemplate) {
      case 'firstTemplate':
        this.currentTemplate = 'secondTemplate';
        break;
      case 'secondTemplate':
        this.currentTemplate = 'thirdTemplate';
        break;
    }
  }

  previousSlide(): void {
    switch (this.currentTemplate) {
      case 'secondTemplate':
        this.currentTemplate = 'firstTemplate';
        break;
      case 'thirdTemplate':
        this.currentTemplate = 'secondTemplate';
        break;
    }
  }
  setInfoLocalStorage(event: any ) {
    const isChecked = event.target.checked;
    this.showSliders.next(isChecked);
  }
  setNotViewMoreMobile() {
    this.showSliders.next(true);
    this.closeModal();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number) {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576 ? this.mobileScreen = false : this.mobileScreen = true;
  }
}
