import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { RegistrationPasswordComponent } from './registration-password/registration-password.component';
import { RouterModule } from '@angular/router';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RedefinePasswordComponent } from './redefine-password/redefine-password.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [LoginComponent, RegistrationComponent,RegistrationPasswordComponent, RecoverPasswordComponent, RedefinePasswordComponent],
  imports: [CommonModule,SharedModule,FormsModule, RouterModule, NgxMaskModule.forRoot(),],
  exports: [LoginComponent,RegistrationPasswordComponent ]
})
export class CredentialsModule { }
