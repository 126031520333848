<div class="container-calendar">
  <div class="time-options-calendars">
    <div class="time-options">
      <button
        [class.highlight]="!selectedRangeStartDate && !selectedRangeEndDate"
        (click)="getAllPeriod()"
      >
        Todo período
      </button>
      <button [class.highlight]="isDayDifference(7)" (click)="getLast7Days()">
        Últimos 7 dias
      </button>
      <button [class.highlight]="isDayDifference(30)" (click)="getLast30Days()">
        Últimos 30 dias
      </button>
      <button [class.highlight]="isBetweenLastYear()" (click)="getLastYear()">
        Ano passado
      </button>
    </div>

    <div class="vertical-line"></div>

    <div class="calendars">
      <div class="calendar">
        <div class="calendar-header">
          <button
            class="arrow-button"
            (click)="previousStep()"
            [disabled]="isSeptember2022"
          >
            <i class="bi bi-chevron-left"></i>
          </button>
          <h3 class="month-title">{{ previousMonth }}</h3>
        </div>
        <app-calendar-view
          [month]="currentDate?.getMonth() - 1"
          [year]="currentDate?.getFullYear()"
          [currentDate]="currentDate"
          [selectedDates]="selectedDates"
          [selectedRangeStartDate]="selectedRangeStartDate"
          [selectedRangeEndDate]="selectedRangeEndDate"
          [selectingStartDate]="selectingStartDate"
          (dateSelected)="onSelectDate($event)"
        ></app-calendar-view>
      </div>

      <div class="calendar">
        <div class="calendar-header">
          <h3 class="month-title">{{ currentMonth }}</h3>
          <button
            class="arrow-button"
            (click)="nextStep()"
            [disabled]="nextMonthIsAfterToday"
          >
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
        <app-calendar-view
          [month]="currentDate?.getMonth()"
          [year]="currentDate?.getFullYear()"
          [currentDate]="currentDate"
          [selectedDates]="selectedDates"
          [selectedRangeStartDate]="selectedRangeStartDate"
          [selectedRangeEndDate]="selectedRangeEndDate"
          [selectingStartDate]="selectingStartDate"
          (dateSelected)="onSelectDate($event)"
        ></app-calendar-view>
      </div>
    </div>
  </div>

  <hr class="line" />

  <div class="container-apply-period">
    <button type="button" class="cancel" (click)="onCloseModal()">
      Cancelar
    </button>
    <button type="button" class="apply-period" (click)="applySelectedRange()">
      Aplicar
    </button>
  </div>
</div>
