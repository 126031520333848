import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[noWhiteSpace]'
})
export class noWhiteSpaceDirective {

  constructor(
    private el: ElementRef
  ) {
  }
  @HostListener('input') onInput(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = inputElement.value;
    const newValue = value.replace(/\s/g, ''); // Remove all whitespace characters

    if (value !== newValue) {
      inputElement.value = newValue;
      inputElement.dispatchEvent(new Event('input')); // Trigger input event to notify Angular forms
    }
  }
}
