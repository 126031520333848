<div class="datepicker">
  <bs-datepicker-inline (bsValueChange)="setDate($event)" [bsConfig]="bsConfig" [bsValue]="selectedDate"></bs-datepicker-inline>

  <p *ngIf="showAlert" class="alert">Selecione um dia para continuar</p>
  <p *ngIf="!showAlert" class="selected-date">{{ formattedDate }}</p>

  <button class="confirm" (click)="confirm()">
    Confirmar
  </button>
</div>
