import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

export interface RequestEvent {
  value: any;
  itemsPerPage?: number;
}
@Component({
  selector: 'app-infinite-select',
  templateUrl: './infinite-select.component.html',
  styleUrls: ['./infinite-select.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  }
})
export class InfiniteSelectComponent implements OnChanges {
  constructor(private elem: ElementRef) { }

  @ViewChild('dropdownListContainer') dropdownContainer: ElementRef;
  @ViewChild('dropdownList') dropdownElement: ElementRef;
  @ViewChild('selectRef') selectRef: ElementRef;
  @ViewChild('inputRef') inputRef: ElementRef;
  @Input() width = '100%';
  @Input() label = 'Select';
  @Input() options: any[];
  @Input() optionTitle: string;
  @Input() optionSubtitle: string;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() disabledButton = false;
  @Input() actionButton = true;
  @Input() createdValue: any;
  @Input() toolTipText: string;
  @Input() required = false;
  @Input() errorMessage: string;
  @Input() showSpecieIcon = false;
  @Input() showSuccessBackground = true;
  @Input() defaultBorderColor = '#00497';
  @Input() focusBorderColor = '#00497';
  @Input() loading = false;
  @Input() fixedValue: boolean = false;
  @Input() inputValue: string = null;
  @Output() scrollToEnd = new EventEmitter<RequestEvent>();
  @Output() loadItems = new EventEmitter<void>();
  @Output() searchChange = new EventEmitter<RequestEvent>();
  @Output() optionSelected = new EventEmitter<any>();
  @Output() onActionButton = new EventEmitter<void>();
  selectPosition: 'above' | 'below' = 'below';
  currentValue: any;
  searchValue = '';
  dropdownOpen = false;
  inputFocused = false;

  private currentIndex = -1;
  private itemsPerPage = 10;
  
  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.selectRef?.nativeElement?.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  ngOnInit() {
  }

  @HostListener('window:scroll', []) onWindowScroll(): void {
    this.updateSelectPosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.searchValue = '';
      this.currentValue = null;
    }

    if(changes.inputValue && this.fixedValue)
    {
      this.searchValue = this.inputValue;
      this.currentValue = this.inputValue;
    }

    if (changes.createdValue) {
      if (this.createdValue) {
        this.select(this.createdValue);
      }
    }

    this.updateSelectPosition();
  }

  handleKeyboardEvents($event: KeyboardEvent): void {
    if (!this.dropdownOpen) {
      return;
    }

    if ($event.code === 'Backspace') {
      if (this.currentValue) {
        this.clearInput();
      }
    }

    if ($event.code === 'ArrowUp') {
        $event.preventDefault();
        if (this.currentIndex < 0) {
            this.currentIndex = 0;
        } else if (this.currentIndex > 0) {
            this.currentIndex--;
        }
        this.elem.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
    } else if ($event.code === 'ArrowDown') {
        $event.preventDefault();
        if (this.currentIndex < 0) {
            this.currentIndex = 0;
        } else if (this.currentIndex < this.options.length - 1) {
            this.currentIndex++;
        }
        this.elem.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
    } else if (($event.code === 'Enter' || $event.code === 'NumpadEnter') && this.currentIndex >= 0) {
        this.selectByIndex(this.currentIndex);
    } else if ($event.code === 'Escape') {
        this.closeDropdown();
    }
}

  onScroll(): void {
    this.itemsPerPage += 10;
    this.scrollToEnd.emit({value: this.searchValue, itemsPerPage: this.itemsPerPage });
  }

  onAction(): void {
    this.onActionButton.emit();
  }

  onSearch(event: any): void {
    this.dropdownOpen = true;
    this.searchValue = event.target.value;
    this.searchChange.emit({value: this.searchValue});
    this.itemsPerPage = 10;
  }

  clearInput(): void{
    this.searchValue = '';
    this.currentValue = null;
    this.searchChange.emit({ value: this.searchValue });
    this.optionSelected.emit(null);
    this.itemsPerPage = 10;
  }

  handleShowItems(): void{
    if (this.disabled) {
      return;
    }

    this.dropdownOpen = !this.dropdownOpen;
  }

  select(value: any): void {
    if (!this.isEqual(this.currentValue, value)) {
      this.currentValue = value;
      this.optionSelected.emit(this.currentValue);
      this.searchValue = this.optionSubtitle && value[this.optionSubtitle] ? `${this.title} - ${value[this.optionSubtitle]}` : this.title;
      this.searchChange.emit( {value: value[this.optionTitle]} );
    }

    this.closeDropdown();
  }

  selectByIndex(i: number): void {
    const value = this.options[i];
    this.select(value);
  }

  closeDropdown(): void {
    this.currentIndex = -1;
    this.dropdownOpen = false;
    this.itemsPerPage = 10;
  }

  isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  handleFocus(isFocused: boolean): void {
    if (!this.focusBorderColor) {
      this.inputFocused = false;
    } else {
      this.inputFocused = isFocused;
    }
  }

  get title(): string {
    if (this.optionSubtitle) {
      return this.currentValue[this.optionTitle].length > 30 ?
        this.currentValue[this.optionTitle].slice(0, 30) + '...' :
        this.currentValue[this.optionTitle];
    }

    return this.currentValue[this.optionTitle];
  }

  calculateTopOffset(): string {
    const maxItems = 5;
    const itemHeight = 32;
    const numItems = this.options?.length || 0;

    if (this.selectPosition === 'above') {
      return `-${Math.min(numItems, maxItems) * itemHeight}px`;
    } else {
      return 'inherit';
    }
  }

  private updateSelectPosition(): void {
    const inputRect = this.inputRef?.nativeElement?.getBoundingClientRect();
    const optionsContainer = this.dropdownContainer?.nativeElement;
    const options = this.dropdownElement?.nativeElement;


    if (inputRect && optionsContainer && !options) {
      const windowHeight = window.innerHeight;
      const spaceAbove = inputRect.top;
      const spaceBelow = windowHeight - inputRect.bottom;
      const maxOptionsHeight = 220;

      // Determine if the input is close to the bottom of the window
      const isInputNearBottom = windowHeight - inputRect.bottom <= maxOptionsHeight;

      if (spaceBelow >= optionsContainer.clientHeight && !isInputNearBottom) {
        this.selectPosition = 'below';
      } else {
        this.selectPosition = 'above';
      }
    }
  }
}
