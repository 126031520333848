import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-footer',
  templateUrl: './generic-footer.component.html',
  styleUrls: ['./generic-footer.component.scss']
})
export class GenericFooterComponent {

}
