import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { DateRangeService } from 'src/app/services/calendar/date-range.service';

const SEVEN_DAYS = 6;
const THIRTY_DAYS = 29;

@Component({
  selector: 'app-calendar-mobile',
  templateUrl: './calendar-mobile.component.html',
  styleUrls: ['./calendar-mobile.component.scss'],
})
export class CalendarMobileComponent implements OnInit, AfterViewInit {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() allPeriod: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedRangeChanged: EventEmitter<{
    startDate: Date;
    endDate: Date;
  }> = new EventEmitter<{ startDate: Date; endDate: Date }>();
  @Output() filterApplied: EventEmitter<void> = new EventEmitter<void>();

  currentMonth: string;
  previousMonth: string;
  currentDate: Date;
  selectedDates: Date[] = [];
  scrollPositionAdjusted = false;

  monthsList: Date[] = [];

  selectedRangeStartDate: Date | null = null;
  selectedRangeEndDate: Date | null = null;
  selectingStartDate = true;

  modalOpen = true;

  constructor(private dateRangeService: DateRangeService) {}

  ngOnInit(): void {
    this.selectedRangeStartDate = this.dateRangeService.selectedRangeStartDate;
    this.selectedRangeEndDate = this.dateRangeService.selectedRangeEndDate;
    const currentDate = new Date();
    const startDate = new Date('2022-09-01');
    const maxMonths = this.calculateMonthDifference(currentDate, startDate);
    this.fillMonthsList(currentDate, startDate, maxMonths);
    this.scrollBodyLocky();
  }

  ngAfterViewInit(): void {
    if (!this.scrollPositionAdjusted) {
      this.adjustScrollPosition();
    }
  }

  scrollBodyLocky() {
    this.modalOpen
      ? ((document.body.style.overflowY = 'hidden'),
        (document.body.style.position = 'fixed'))
      : ((document.body.style.overflowY = 'auto'),
        (document.body.style.position = 'inherit'));
  }

  isDayDifference(days: number): boolean {
    if (this.selectedRangeStartDate && this.selectedRangeEndDate) {
      const differenceInDays = Math.ceil(
        Math.abs(
          (this.selectedRangeEndDate.getTime() -
            this.selectedRangeStartDate.getTime()) /
            (1000 * 3600 * 24)
        )
      );

      return differenceInDays === days;
    }

    return false;
  }

  isBetweenLastYear(): boolean {
    if (this.selectedRangeStartDate && this.selectedRangeEndDate) {
      const differenceInDays = Math.ceil(
        Math.abs(
          (this.selectedRangeEndDate.getTime() -
            this.selectedRangeStartDate.getTime()) /
            (1000 * 3600 * 24)
        )
      );

      return differenceInDays === 365 || differenceInDays === 366;
    }

    return false;
  }

  applySelectedRange(): void {
    if (this.selectedRangeStartDate && this.selectedRangeEndDate) {
      this.dateRangeService.setRange(
        this.selectedRangeStartDate,
        this.selectedRangeEndDate
      );

      this.selectedRangeChanged.emit({
        startDate: this.selectedRangeStartDate,
        endDate: this.selectedRangeEndDate,
      });

      this.filterApplied.emit();
      this.onCloseModal();
    }
  }

  getAllPeriod() {
    this.allPeriod.emit();
    this.dateRangeService.clearRange();
    this.onCloseModal();
  }

  getLast7Days() {
    this.setDateRangeWithDaysAgo(SEVEN_DAYS);
    this.onCloseModal();
  }

  getLast30Days() {
    this.setDateRangeWithDaysAgo(THIRTY_DAYS);
    this.onCloseModal();
  }

  getLastYear() {
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    const lastYearStartDate = new Date(currentDate);
    lastYearStartDate.setFullYear(currentDate.getFullYear() - 1, 0, 1);

    const lastYearEndDate = new Date(currentDate);

    lastYearEndDate.setFullYear(currentDate.getFullYear() - 1, 11, 31);
    lastYearEndDate.setHours(23, 59, 59);

    this.selectedRangeStartDate = lastYearStartDate;
    this.selectedRangeEndDate = lastYearEndDate;

    this.applySelectedRange();
    this.filterApplied.emit();
    this.onCloseModal();
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };

    const formattedDate = date.toLocaleDateString('pt-BR', options);
    return formattedDate;
  }

  formatMonthTitle(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      year: 'numeric',
    };
    const formattedMonth = date.toLocaleDateString('pt-BR', options);

    return formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
  }

  isDateSelected(date: Date): boolean {
    return this.selectedDates.some(
      (selectedDate) =>
        selectedDate.getDate() === date.getDate() &&
        selectedDate.getMonth() === date.getMonth() &&
        selectedDate.getFullYear() === date.getFullYear()
    );
  }

  onSelectDate(date: Date): void {
    if (this.selectingStartDate) {
      this.selectedRangeStartDate = date;
      this.selectedRangeEndDate = date;
    } else {
      if (this.selectedRangeStartDate && date >= this.selectedRangeStartDate) {
        this.selectedRangeEndDate = date;
      }
    }

    this.selectingStartDate = !this.selectingStartDate;
  }

  onCloseModal() {
    this.modalOpen = false;
    this.scrollBodyLocky();
    this.closeModal.emit();
  }

  private adjustScrollPosition(): void {
    const calendars = document.querySelector('.calendars');
    if (calendars) {
      calendars.scrollTop = calendars.scrollHeight;
      this.scrollPositionAdjusted = true;
    }
  }

  private setDateRangeWithDaysAgo(daysAgo: number) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - daysAgo);

    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59);

    this.selectedRangeStartDate = startDate;
    this.selectedRangeEndDate = endDate;

    this.applySelectedRange();
    this.filterApplied.emit();
  }

  private calculateMonthDifference(date1: Date, date2: Date): number {
    const monthsInYear = 12;

    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();

    return Math.abs(yearDiff * monthsInYear + monthDiff);
  }

  private fillMonthsList(
    startDate: Date,
    endDate: Date,
    maxMonths: number
  ): void {
    const currentDate = new Date(startDate);
    for (let i = 0; i < maxMonths && currentDate > endDate; i++) {
      this.monthsList.push(new Date(currentDate));

      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    this.monthsList.reverse();
  }
}
